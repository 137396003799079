import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import {
  currentBusinessEntityIdSelector,
  currentUserSelector,
} from 'features/common/commonSelectors';
import { getCurrentUser } from 'features/common/commonActions';
import { getClassifications } from 'features/classification/classificationActions';
import { api, getErrHandler } from 'features/api';
import { Header } from 'components/Header';
import { useAuth } from 'react-oidc-context';
import { Audit } from 'routes/Audit';
import { Users } from 'routes/Users';
import { Alliances } from 'routes/Alliances';
import { Organizations } from 'routes/Organizations';
import { Stops } from 'routes/Stops';
import { Ims } from 'routes/configuration/Ims';
import { StopCodeLists } from 'routes/configuration/StopCodeLists';
import { OrganizationCodeLists } from 'routes/configuration/OrganizationCodeLists';
import { useAlert } from 'react-alert';
import { CardTypes } from 'routes/configuration/CardTypes';
import AuthWrapper from 'AuthWrapper';
import { AppVersion } from '@fleet/shared';

export const Application = () => {
  const alert = useAlert();
  const auth = useAuth();
  const dispatch = useDispatch();
  const loading = useSelector(appLoadingSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const { isPlatform: isPlatformUser } = useSelector(currentUserSelector);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getCurrentUser());
      dispatch(getClassifications());
    }
  }, [auth.isAuthenticated, dispatch, currentBusinessEntityId]);

  useEffect(() => {
    const errInterceptor = api.interceptors.response.use(
      (res) => res,
      getErrHandler(auth, alert)
    );
    return () => api.interceptors.response.eject(errInterceptor);
  }, [alert, auth]);

  return (
    <>
      <BrowserRouter>
        <AuthWrapper appLoading={loading}>
          <Header />
          <Switch>
            <Route path="/audit/:id?" component={Audit} />
            <Route path="/users/:action(create|edit)?/:id?" component={Users} />
            <Route
              path="/alliances/:action(create|edit)?/:id?"
              component={Alliances}
            />
            <Route path="/organizations" component={Organizations} />
            <Route path="/stops/:action(create|edit)?/:id?" component={Stops} />
            {isPlatformUser && (
              <Switch>
                <Route
                  path="/stop-code-lists/:action(create|edit)?/:id?"
                  component={StopCodeLists}
                />
                <Route path="/ims/:action(create|edit)?/:id?" component={Ims} />
                <Route
                  path="/organization-code-lists/:action(create|edit)?/:id?"
                  component={OrganizationCodeLists}
                />
                <Route
                  path="/card-types/:action(create|edit)?/:id?"
                  component={CardTypes}
                />
              </Switch>
            )}
          </Switch>
        </AuthWrapper>
      </BrowserRouter>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
};
