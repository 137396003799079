import { createAsyncThunk } from 'store/utils';
import { api } from 'features/api';
import { saveAs } from 'file-saver';

export const getBaseDataFile = createAsyncThunk<
  void,
  { fileId: string } & { fileName: string }
>(
  'files/getBaseDataFile',
  async ({ fileId, fileName }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/files/${fileId}`, {
        baseURL: process.env.REACT_APP_API_BD,
        responseType: 'blob',
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);

      saveAs(url, fileName);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
