import { createReducer } from '@reduxjs/toolkit';
import {
  getClassification,
  getCountries,
  getStopCodeList,
  getOrganizationCodeList,
  getImsList
} from 'features/classification/classificationActions';
import { Classification } from 'dto/classification';

const initialState: Record<string, Array<Classification>> = {};
export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassification.fulfilled, (state, action) => {
      const [groupName, data] = action.payload;
      state[groupName] = data;
    })
    .addCase(getStopCodeList.fulfilled, (state, action) => {
      state.STOP_CODE_LIST = action.payload;
    })
    .addCase(getOrganizationCodeList.fulfilled, (state, action) => {
      state.ORGANIZATION_CODE_LIST = action.payload;
    })
    .addCase(getImsList.fulfilled, (state, action) => {
      state.IMS = action.payload;
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.COUNTRY = action.payload;
    });
});
