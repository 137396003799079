import { FC, useCallback, useEffect, useMemo } from 'react';
import { Loadable, FormProvider, useForm, Modal } from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import { Tooltip, Icon } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { codeListsLoadingSelector } from 'features/loading/loadingSelectors';
import { useHistory, useParams } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import {
  getCodeLists,
  setCodeList,
  getCodeListById,
  updateCodeList,
  createCodeList,
  deleteCodeList,
} from 'features/codelists/codeListsActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { useModal } from '@fleet/shared/hooks';
import { codeListItemSelector } from 'features/codelists/codeListsSelectors';
import { CodeListItem } from 'dto/codeLists';
import { TransModal } from 'i18n/trans/modal';

const useStyles = makeStyles(
  (theme) => ({
    formFields: {
      '&& > .MuiFormControl-root': {
        '&:not($formSwitch)': {
          marginBottom: 16,
        },

        '& > label': {
          color: theme.palette.text.primary,
          fontSize: 14,
          flexGrow: 1,
        },
      },
    },
    formSwitch: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      '& .Icon-root': {
        pointerEvents: 'all',
      },
    },
  }),
  {
    name: 'CodeListForm',
  }
);

interface CodeListFormProps {}

export const CodeListForm: FC<CodeListFormProps> = () => {
  const dispatch = useDispatch();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  useEffect(() => {
    dispatch(setCodeList());
    if (action === 'edit' && id) {
      dispatch(getCodeListById({ id, type: 'stop' }));
    }
    return () => {
      dispatch(setCodeList());
    };
  }, [action, dispatch, id]);
  const currentCodeItem = useSelector(codeListItemSelector);
  const classes = useStyles();
  const loading = useSelector(codeListsLoadingSelector);
  const { open: isOpen, onOpen, onClose } = useModal();
  const history = useHistory();
  const closeDrawer = useCallback(() => {
    history.replace('/stop-code-lists');
  }, [history]);

  const alert = useAlert();
  const onSubmit = useCallback(
    async (values: CodeListItem) => {
      if (id) {
        await dispatch(updateCodeList(values)).unwrap();
        if (currentCodeItem) {
          alert.success(<TransAlert i18nKey="stopCodeListSaved" />);
          closeDrawer();
        }
      } else {
        const { id } = await dispatch(
          createCodeList({ t: 'stop', ...values })
        ).unwrap();

        if (id) {
          alert.success(<TransAlert i18nKey="stopCodeListCreated" />);
          closeDrawer();
        }
      }
      await dispatch(getCodeLists('stop'));
    },
    [alert, currentCodeItem, closeDrawer, dispatch, id]
  );

  const initialValues = useMemo(
    () => ({ ...currentCodeItem }),
    [currentCodeItem]
  );

  const { form, handleSubmit, submitting } = useForm<CodeListItem>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });
  const { reset } = form;

  useEffect(() => {
    if (!currentCodeItem) {
      reset({});
    }
  }, [currentCodeItem, reset]);

  const handleDelete = useCallback(
    async (id: string) => {
      await dispatch(deleteCodeList({ id, type: 'stop' })).unwrap();
      await dispatch(getCodeLists('stop'));

      alert.success(<TransAlert i18nKey="stopCodeListDeleted" />);
      closeDrawer();
    },
    [alert, closeDrawer, dispatch]
  );

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Stack
          sx={{
            width: '400px',
            height: '100vh',
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <CardHeader
            sx={{ px: 3, py: 2 }}
            title={
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="subtitle">
                  <TransTitle i18nKey="stopCodeList" />
                </Typography>
                {id && (
                  <>
                    <Button
                      startIcon={<Icon name="trash" size={16} />}
                      size="small"
                      onClick={onOpen}
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                    <Modal
                      open={isOpen}
                      onClose={onClose}
                      title={<TransModal i18nKey="deleteCodeList" />}
                      message={`Are you sure you want to delete stop code list '${currentCodeItem?.name}'? It cannot be undone.`}
                      actionButton={
                        <Button
                          type="submit"
                          startIcon={<Icon name="trash" />}
                          variant="contained"
                          color="error"
                          disabled={submitting}
                          onClick={() => handleDelete(id)}
                        >
                          <TransButton i18nKey="delete" />
                        </Button>
                      }
                    />
                  </>
                )}
              </Stack>
            }
            action={
              <IconButton aria-label="close" onClick={closeDrawer}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent
            sx={{ px: 3, py: 2, flex: 1, overflowy: 'scroll' }}
            className={classes.formFields}
          >
            <TextField
              label={<TransField i18nKey="name" />}
              name="name"
              required
            />
            <TextField label={<TransField i18nKey="prefix" />} name="prefix" />
          </CardContent>
          <CardActions
            sx={{ padding: 3, justifyContent: 'flex-end', boxShadow: 2 }}
          >
            <Button variant="text" color="primary" onClick={closeDrawer}>
              <TransButton i18nKey="cancel" />
            </Button>
            {id ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Icon name="check" />}
              >
                <TransButton i18nKey="save" />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="add" />
              </Button>
            )}
          </CardActions>
        </Stack>
      </FormProvider>
    </Loadable>
  );
};
