import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { classificationsSelector } from 'features/classification/classificationSelectors';
import { useMemo } from 'react';

type GetClassifier<T extends { id: unknown; name: unknown }> = T extends infer U
  ? U extends { id: unknown }
    ? U
    : T
  : never;

export const makeClassificationOptions = <
  T extends { id: unknown; name: string }
>(
  data: Array<T>
): Array<{ value: GetClassifier<T>['id']; label: T['name'] }> =>
  data.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

export const useClassificationOptions = (groupName: ClassificationGroup) => {
  const classifications = useSelector(classificationsSelector);
  return useMemo(
    () =>
      (classifications[groupName] ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [classifications, groupName]
  );
};

export const useClassificationMap = (groupName: ClassificationGroup) => {
  const classifications = useClassificationOptions(groupName);
  return useMemo(
    () => new Map(classifications.map(({ value, label }) => [value, label])),
    [classifications]
  );
};
