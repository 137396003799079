import { createReducer } from '@reduxjs/toolkit';
import {
  getBookingAccessRights,
  getOrganizationsList,
  setCurrentOrganization,
  updateOrganization,
  setOrganizationsFilter,
  getRetailersList,
  getSalesPointClient,
  getStopOrganization,
  cleanOrganizationsSearch,
  getOrganizationFiles,
} from 'features/organizations/organizationActions';
import {
  Application,
  BookingAccessRight,
  Organization,
  OrganizationDetails,
  OrganizationFile,
  OrganizationSearchFilter,
} from 'dto/organization';
import { StopCode } from 'dto/stop';
import { Pagination } from '@fleet/shared/dto/pagination';

interface OrganizationState {
  search?: Pagination<Organization>;
  current?: OrganizationDetails;
  bookingAccessRights: Array<BookingAccessRight>;
  retailers: Array<Organization>;
  filter?: Partial<OrganizationSearchFilter>;
  clientId?: Array<Application>;
  stopCode?: Array<StopCode>;
  organizationFiles?: Array<OrganizationFile>;
}

const initialState: OrganizationState = {
  retailers: [],
  bookingAccessRights: [],
};

export const organizationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOrganizationsList.rejected, (state) => {
      state.search = undefined;
    })
    .addCase(getOrganizationsList.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(getOrganizationFiles.rejected, (state) => {
      state.organizationFiles = undefined;
    })
    .addCase(getOrganizationFiles.fulfilled, (state, action) => {
      state.organizationFiles = action.payload;
    })
    .addCase(setCurrentOrganization, (state, action) => {
      state.current = action.payload;
    })
    .addCase(updateOrganization.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getBookingAccessRights.fulfilled, (state, action) => {
      state.bookingAccessRights = action.payload;
    })
    .addCase(getRetailersList.fulfilled, (state, action) => {
      state.retailers = action.payload;
    })
    .addCase(getSalesPointClient.fulfilled, (state, action) => {
      state.clientId = action.payload;
    })
    .addCase(setOrganizationsFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(cleanOrganizationsSearch, (state) => {
      state.search = undefined;
    })
    .addCase(getStopOrganization.fulfilled, (state, action) => {
      state.stopCode = action.payload;
    });
});
