import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Box, alpha } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { CardType } from 'dto/cardType';

const useStyles = makeStyles(
  (theme) => ({
    cardTypesCard: {
      height: '100%',
      background: theme.palette.background.default,
      cursor: 'pointer',

      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.1),
      },
    },
    typog: {
      color: '#2C2C2C',
      fontWeight: 400,
      paddingBottom: 10,
    },
  }),
  {
    name: 'PassengerTypes',
  }
);

interface CardTypeCardProps {
  cardType: CardType;
}

export const CardTypeCard: FC<CardTypeCardProps> = ({ cardType }) => {
  const classes = useStyles();
  const history = useHistory();

  const { id, name, isCardNumberMandatory, type, issuer } = cardType;

  return (
    <Box
      p={2}
      component={Card}
      variant="outlined"
      className={classes.cardTypesCard}
      square
      onClick={() => history.push(`/card-types/edit/${id}`)}
    >
      <Typography
        style={{ wordBreak: 'break-word', marginBottom: '16px' }}
        variant="subtitle"
        component="h3"
        color="#000000"
      >
        {name}
      </Typography>
      <Typography
        style={{ wordBreak: 'break-word', fontWeight: 400 }}
        variant="subtitle"
        component="h3"
        color="text.secondary"
      >
        <TransTitle i18nKey="category" />
      </Typography>
      <Typography
        style={{ wordBreak: 'break-word' }}
        variant="subtitle"
        component="h3"
        className={classes.typog}
      >
        {type.name}
      </Typography>
      <Typography
        style={{ wordBreak: 'break-word', fontWeight: 400 }}
        variant="subtitle"
        component="h3"
        color="text.secondary"
      >
        <TransTitle i18nKey="mandatoryCardNumber" />
      </Typography>
      <Typography
        style={{ wordBreak: 'break-word' }}
        variant="subtitle"
        component="h3"
        className={classes.typog}
      >
        <TransField i18nKey={isCardNumberMandatory ? 'yes' : 'no'} />
      </Typography>
      <Typography
        style={{ wordBreak: 'break-word', fontWeight: 400 }}
        variant="subtitle"
        component="h3"
        color="text.secondary"
      >
        <TransTitle i18nKey="issuer" />
      </Typography>
      <Typography
        style={{ wordBreak: 'break-word' }}
        variant="subtitle"
        component="h3"
        className={classes.typog}
      >
        {issuer.name}
      </Typography>
    </Box>
  );
};
