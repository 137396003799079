import { createAsyncThunk } from 'store/utils';
import { api } from 'features/api';
import { createAction } from '@reduxjs/toolkit';
import {
  CardType,
  CardTypeTranslation,
  CardTypeTranslations,
  CardTypePayload,
  CardTypeTranslationPayload,
} from 'dto/cardType';

export const getCardTypes = createAsyncThunk<Array<CardType>>(
  'cardTypes/getCardTypes',
  async () => (await api.get(`/card-type`)).data.items
);

export const setCardType = createAction<CardType | undefined>(
  'cardTypes/setCardType'
);

export const getCardType = createAsyncThunk<void, string>(
  'cardTypes/getCardType',
  async (id, { dispatch }) => {
    const cardType = (await api.get(`/card-type/${id}`)).data;
    let translations: CardTypeTranslations = [];

    if (cardType) {
      translations = (
        await api.get<{ items: CardTypeTranslations }>(
          `/card-type/${cardType.id}/localizations`
        )
      ).data.items;
      dispatch(setCardType({ ...cardType, translations }));
    } else {
      dispatch(setCardType());
    }
  }
);

export const createOrUpdateCardType = createAsyncThunk<
  CardType,
  Partial<CardTypePayload>
>(
  'cardTypes/createOrUpdateCardType',
  async ({ id, ...data }) =>
    (await (id ? api.put : api.post)(`/card-type/${id ? `/${id}` : ''}`, data))
      .data
);

export const deleteCardType = createAsyncThunk<void, string>(
  'cardTypes/deleteCardType',
  async (id) => {
    await api.delete(`/card-type/${id}`);
  }
);

export const getCardTypeLocalizations = createAsyncThunk<
  CardTypeTranslations,
  string
>(
  'cardTypes/getCardTypeLocalizations',
  async (id) => (await api.get(`/card-type/${id}/localizations`)).data.items
);

export const createOrUpdateCardTypeLocalization = createAsyncThunk<
  CardTypeTranslation,
  Partial<CardTypeTranslationPayload>
>(
  'cardTypes/createOrUpdateCardTypeLocalization',
  async ({ id, cardTypeId, ...data }) =>
    (
      await (id ? api.put : api.post)(
        `/card-type/${cardTypeId}/localizations${id ? `/${id}` : ''}`,
        data
      )
    ).data
);

export const deleteCardTypeLocalization = createAsyncThunk<
  void,
  Partial<CardTypeTranslationPayload>
>('cardTypes/deleteCardTypeLocalization', async ({ id, cardTypeId }) => {
  await api.delete(`/card-type/${cardTypeId}/localizations/${id}`);
});
