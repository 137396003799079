import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { userAssignOrganizationLoadingSelector } from 'features/loading/loadingSelectors';
import {
  assignUserOrganization,
  deleteUserOrganization,
  getUser,
} from 'features/users/userActions';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { Icon, Loadable, Modal } from '@fleet/shared';
import { OrganizationsTable } from 'routes/organizations/OrganizationsTable';
import { useDispatch, useSelector } from 'store/utils';
import { userSelector } from 'features/users/userSelectors';
import { TransButton } from 'i18n/trans/button';
import { useModal } from '@fleet/shared/hooks';
import { TransModal } from 'i18n/trans/modal';
import { organizationsSelector } from 'features/organizations/organizationSelectors';
import { Organization } from 'dto/organization';
import { Row } from 'react-table';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(3),
      width: '100%',
      '& .MuiDialog-container ': {
        width: '100%',
      },
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: theme.breakpoints.values.lg,
        minHeight: '100%',
        '& .MuiDialogContent-root': {
          display: 'flex',
          minHeight: 0,
          overflow: 'unset',
          '& .MuiAccordionSummary-root': {
            display: 'none',
          },
        },
      },
    },
  }),
  {
    name: 'UsersSearchForm',
  }
);

interface UserOrganizationsAddRelatedModalProps {}

export const UserOrganizationsAddRelatedModal: FC<UserOrganizationsAddRelatedModalProps> =
  () => {
    const [selectedOrgIds, setSelectedOrgIds] = useState<string[]>([]);
    const { open: isOpen, onOpen, onClose } = useModal();
    const dispatch = useDispatch();
    const currentUser = useSelector(userSelector);
    const filteredOrganizations = useSelector(organizationsSelector);
    const loading = useSelector(userAssignOrganizationLoadingSelector);
    const currentUserId = currentUser?.id;

    const filteredOrganizationIds = useMemo(() => {
      return filteredOrganizations?.items.map((org) => org.id);
    }, [filteredOrganizations?.items]);

    const organizationInitialState = useMemo(
      () => ({
        pageSize: 10,
        selectedRowIds:
          currentUser?.organizations.reduce<{ [id: string]: boolean }>(
            (acc, { id }) => {
              if (filteredOrganizationIds?.includes(id)) {
                return { ...acc, [id]: true };
              }
              return acc;
            },
            {}
          ) ?? {},
      }),
      [currentUser?.organizations, filteredOrganizationIds]
    );

    const onSelectedRowsChange = useCallback((rows: Row<Organization>[]) => {
      setSelectedOrgIds(rows.map((row) => row.original.id));
    }, []);

    const handleSubmit = useCallback(async () => {
      const initialOrgIds = Object.keys(
        organizationInitialState.selectedRowIds
      );

      if (!initialOrgIds.length && !selectedOrgIds.length) {
        return onClose();
      }

      const organizationsToAdd = selectedOrgIds.filter(
        (selectedOrg) => !initialOrgIds.includes(selectedOrg)
      );
      const organizationsToRemove = initialOrgIds.filter(
        (initialOrg) => !selectedOrgIds.includes(initialOrg)
      );

      if (!organizationsToAdd?.length && !organizationsToRemove.length) {
        return onClose();
      }

      if (organizationsToAdd) {
        await Promise.all(
          organizationsToAdd.map((org) => {
            return dispatch(
              assignUserOrganization({
                userId: currentUserId!,
                organizationId: org,
              })
            );
          })
        );
      }

      if (organizationsToRemove) {
        await Promise.all(
          organizationsToRemove.map((org) => {
            return dispatch(
              deleteUserOrganization({
                userId: currentUserId!,
                organizationId: org,
              })
            );
          })
        );
      }

      console.log('GET USER');
      if (currentUserId) dispatch(getUser(currentUserId));
      onClose();
    }, [
      currentUserId,
      selectedOrgIds,
      onClose,
      dispatch,
      organizationInitialState.selectedRowIds,
    ]);

    const classes = useStyles();
    return (
      <>
        <Button
          onClick={onOpen}
          startIcon={<Icon name="plus" />}
          sx={{ ml: 'auto' }}
        >
          <TransButton i18nKey="addNew" />
        </Button>
        <Modal
          open={isOpen}
          title={<TransModal i18nKey="addRelatedOrganizations" />}
          onClose={onClose}
          classes={classes}
          actionButton={{
            label: <TransButton i18nKey="confirm" />,
            onClick: handleSubmit,
          }}
          fullWidth
        >
          <Loadable loading={loading}>
            <OrganizationsTable
              actions={false}
              initialState={organizationInitialState}
              onSelectedRowsChange={onSelectedRowsChange}
            />
          </Loadable>
        </Modal>
      </>
    );
  };
