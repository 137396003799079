import { getCurrentUser } from 'features/common/commonActions';
import { getClassifications } from 'features/classification/classificationActions';
import { getAuditTrail, getAuditTrailState } from 'features/audit/auditActions';
import { getStopsList } from 'features/stops/stopsActions';
import { createLoadingSelector } from 'store/utils';
import {
  assignUserOrganization,
  assignUserOrganizationPointOfSale,
  assignUserOrganizationUnit,
  assignUserRole,
  deleteUserOrganization,
  deleteUserOrganizationPointOfSale,
  deleteUserOrganizationUnit,
  deleteUserRole,
  getUser,
  getUsers,
} from 'features/users/userActions';
import {
  activateOrganization,
  createOrganization,
  deactivateOrganization,
  getOrganization,
  getOrganizationsList,
  updateOrganization,
  getSalesPointClient,
  createSalesPointClient,
  getOrganizationFiles,
  updateOrCreateOrganizationFile,
  deleteOrganizationFiles,
} from 'features/organizations/organizationActions';

import {
  assignAllianceOrganization,
  createAlliance,
  deleteAlliance,
  deleteAllianceOrganization,
  getAlliance,
  getAlliancesList,
  updateAlliance,
} from 'features/alliances/alliancesActions';

import {
  getImsConfigurations,
  createIms,
  updateIms,
  getImsById,
} from 'features/ims/imsActions';
import {
  getCodeLists,
  getCodeListById,
} from 'features/codelists/codeListsActions';
import { getBaseDataFile } from 'features/files/fileActions';
import {
  createOrUpdateCardType,
  deleteCardType,
  getCardType,
  getCardTypes,
} from 'features/cardtypes/cardTypesActions';

export const appLoadingSelector = createLoadingSelector(
  getCurrentUser,
  getClassifications
);

export const auditLoadingSelector = createLoadingSelector(
  getAuditTrail,
  getAuditTrailState
);
export const usersLoadingSelector = createLoadingSelector(getUsers);

export const imsLoadingSelector = createLoadingSelector(
  getImsConfigurations,
  createIms,
  updateIms,
  getImsById
);

export const cardTypesLoadingSelector = createLoadingSelector(
  getCardType,
  getCardTypes,
  createOrUpdateCardType,
  deleteCardType
);

export const codeListsLoadingSelector = createLoadingSelector(
  getCodeLists,
  getCodeListById
);
export const currentUserLoadingSelector = createLoadingSelector(
  getUser,
  assignUserRole,
  deleteUserRole,
  deleteUserOrganization,
  assignUserOrganizationUnit,
  deleteUserOrganizationUnit,
  assignUserOrganizationPointOfSale,
  deleteUserOrganizationPointOfSale
);
export const userAssignOrganizationLoadingSelector = createLoadingSelector(
  assignUserOrganization,
  deleteUserOrganization
);

export const organizationsListLoadingSelector =
  createLoadingSelector(getOrganizationsList);

export const organizationsFormLoadingSelector = createLoadingSelector(
  activateOrganization,
  updateOrganization,
  deactivateOrganization,
  createOrganization,
  getOrganization
);

export const salesPointClientIdModalLoadingSelector = createLoadingSelector(
  getSalesPointClient,
  createSalesPointClient
);

export const stopListLoadingSelector = createLoadingSelector(getStopsList);

export const alliancesListLoadingSelector =
  createLoadingSelector(getAlliancesList);

export const alliancesLoadingSelector = createLoadingSelector(
  getAlliance,
  updateAlliance,
  createAlliance,
  deleteAlliance,
  deleteAllianceOrganization,
  assignAllianceOrganization
);

export const filesLoadingSelector = createLoadingSelector(
  getBaseDataFile,
  getOrganizationFiles,
  updateOrCreateOrganizationFile,
  deleteOrganizationFiles
);
