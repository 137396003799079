import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  alliancesQty: (
    <Trans i18nKey="subtitle.alliancesQty" defaults="{{num}} alliances" />
  ),
  codes: <Trans i18nKey="subtitle.codes" defaults="Codes" />,
  details: <Trans i18nKey="subtitle.details" defaults="Details" />,
  inactive: <Trans i18nKey="subtitle.inactive" defaults="inactive" />,
  langDependantParams: (
    <Trans
      i18nKey="subtitle.langDependantParams"
      defaults="Language dependent parameters"
    />
  ),
  mandatory: <Trans i18nKey="subtitle.mandatory" defaults="(Mandatory)" />,
  members: <Trans i18nKey="subtitle.members" defaults="Members" />,
  newAlliance: <Trans i18nKey="subtitle.newAlliance" defaults="New alliance" />,
  newOrganization: (
    <Trans i18nKey="subtitle.newOrganization" defaults="New organization" />
  ),
  newStop: <Trans i18nKey="subtitle.newStop" defaults="New stop" />,
  newUser: <Trans i18nKey="subtitle.newUser" defaults="New user" />,
  noAddressesFound: (
    <Trans i18nKey="subtitle.noAddressesFound" defaults="No addresses found" />
  ),
  noBankAccountsFound: (
    <Trans
      i18nKey="subtitle.noBankAccountsFound"
      defaults="No bank accounts found"
    />
  ),
  noBookingAccessRightsFound: (
    <Trans
      i18nKey="subtitle.noBookingAccessRightsFound"
      defaults="No booking access rights found"
    />
  ),
  noCodesFound: (
    <Trans i18nKey="subtitle.noCodesFound" defaults="No codes found" />
  ),
  noContactOptionsFound: (
    <Trans
      i18nKey="subtitle.noContactOptionsFound"
      defaults="No contact options found"
    />
  ),
  noFilesFound: (
    <Trans i18nKey="subtitle.noFilesFound" defaults="No files found" />
  ),
  noPointsOfSalesFound: (
    <Trans
      i18nKey="subtitle.noPointsOfSalesFound"
      defaults="No points of sales found"
    />
  ),
  noServiceFeesFound: (
    <Trans
      i18nKey="subtitle.noServiceFeesFound"
      defaults="No service fees found"
    />
  ),
  noTicketTextsFound: (
    <Trans
      i18nKey="subtitle.noTicketTextsFound"
      defaults="No ticket texts found"
    />
  ),
  noUnitsFound: (
    <Trans i18nKey="subtitle.noUnitsFound" defaults="No units found" />
  ),
  organizationsQty: (
    <Trans
      i18nKey="subtitle.organizationsQty"
      defaults="{{num}} organizations"
    />
  ),
  passwordMissingRequirement: (
    <Trans
      i18nKey="subtitle.passwordMissingRequirement"
      defaults="You are missing:"
    />
  ),
  passwordRequirement: (
    <Trans
      i18nKey="subtitle.passwordRequirement"
      defaults="Password must contain at least three of these:"
    />
  ),
  relatedOrganizations: (
    <Trans i18nKey="subtitle.relatedOrganizations" defaults="Organizations" />
  ),
  roles: <Trans i18nKey="subtitle.roles" defaults="Roles" />,
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
  stopQty: <Trans i18nKey="subtitle.stopQty" defaults="{{num}} stops" />,
  termsAndConditions: (
    <Trans
      i18nKey="subtitle.termsAndConditions"
      defaults="Terms and conditions"
    />
  ),
  thresholds: <Trans i18nKey="table.head.thresholds" defaults="Thresholds" />,
  usersQty: <Trans i18nKey="subtitle.usersQty" defaults="{{num}} users" />,
});
