export enum ClassificationGroup {
  CARD_TYPE = 'CARD_TYPE',
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  ORGANIZATION_ROLE = 'ORGANIZATION_ROLE',
  POINT_OF_SALE_TYPE = 'POINT_OF_SALE_TYPE',
  STOP_CODE_LIST = 'STOP_CODE_LIST',
  ORGANIZATION_FILE = 'ORGANIZATION_FILE',
  ADDRESS_TYPE = 'ADDRESS_TYPE',
  RETAILER_SERVICE_FEE_TYPE = 'RETAILER_SERVICE_FEE_TYPE',
  TEMPLATE_TYPE = 'TEMPLATE_TYPE',
  CONTACTING_OPTION_TYPE = 'CONTACTING_OPTION_TYPE',
  CONTACTING_PURPOSE_TYPE = 'CONTACTING_PURPOSE_TYPE',
  COUNTRY = 'COUNTRY',
  STOP_CODE = 'STOP_CODE',
  ORGANIZATION_CODE_LIST = 'ORGANIZATION_CODE_LIST',
  IMS = 'IMS',
  POS_TYPE = 'POS_TYPE',
  BANK_ACCOUNT_TYPE = 'BANK_ACCOUNT_TYPE',
}

export interface Classifier<T = string> {
  id: T;
  name: string;
}

export interface Classification {
  id: string;
  name: string;
  localizations: Array<{
    languageId: string;
    name: string;
  }>;
}

export interface CodeList extends Classification {
  type: string;
  prefix: string;
}
