import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useEffect, FC } from 'react';
import { Icon, CardHeader } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { cardTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { CardContent, Grid, Button } from '@mui/material';
import { Layout } from '@fleet/shared/components/Layout';
import { Loadable, SearchResult } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { getCardTypes } from 'features/cardtypes/cardTypesActions';
import { cardTypesSelector } from 'features/cardtypes/cardTypesSelectors';
import { CardTypeDrawer } from './cardtypes/CardTypeDrawer';
import { CardTypeCard } from './cardtypes/CardTypeCard';

interface CardTypesProps extends RouteComponentProps {}

export const CardTypes: FC<CardTypesProps> = ({ match }) => {
  const dispatch = useDispatch();
  const loading = useSelector(cardTypesLoadingSelector);
  const cardTypes = useSelector(cardTypesSelector);

  useEffect(() => {
    dispatch(getCardTypes());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="cardTypes" />} sx={{ px: 3 }}>
            <Button
              variant="text"
              startIcon={<Icon name="add" />}
              component={Link}
              to="/card-types/create"
            >
              <TransButton i18nKey="add" />
            </Button>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path={`${match.path}/:action(create|edit)/:id?`}
              component={CardTypeDrawer}
              exact
            />
          </Switch>
        }
      >
        <SearchResult results={cardTypes?.length} i18nKey="noDataAdded">
          <CardContent sx={{ p: 3, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {cardTypes.map((item) => (
                <Grid key={item.id} item sm={4} md={3}>
                  <CardTypeCard cardType={item} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
