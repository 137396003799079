import { createSelector, selector } from 'store/utils';

export const selectOrganizations = selector(
  (state) => state.organization.search
);
export const organizationsSelector = createSelector(selectOrganizations);

export const selectCurrentOrganization = selector(
  (state) => state.organization.current
);
export const currentOrganizationSelector = createSelector(
  selectCurrentOrganization
);

export const selectCurrentOrganizationId = selector(
  (state) => state.organization.current!.id
);

export const selectBookingAccessRights = selector(
  (state) => state.organization.bookingAccessRights
);
export const bookingAccessRightsSelector = createSelector(
  selectBookingAccessRights
);

export const selectOrganizationsFilter = selector(
  (state) => state.organization.filter
);

export const organizationsFilterSelector = createSelector(
  selectOrganizationsFilter
);

export const selectRetailers = selector(
  (state) => state.organization.retailers
);
export const retailersSelector = createSelector(selectRetailers);

export const selectClientId = selector((state) => state.organization.clientId);
export const ClientIdSelector = createSelector(selectClientId);

export const selectStopCode = selector((state) => state.organization.stopCode);
export const OrganizationStopCodeSelector = createSelector(selectStopCode);

export const selectOrganizationFiles = selector(
  (state) => state.organization.organizationFiles
);
export const organizationFilesSelector = createSelector(
  selectOrganizationFiles
);
