import { FC, useCallback, useEffect, useState } from 'react';
import { SelectField, TextField, RadioGroupField } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { ClassificationGroup } from 'dto/classification';
import {
  makeClassificationOptions,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';

import { Grid } from '@mui/material';
import { fetchOrganizations } from 'features/cardtypes/cardTypesService';

interface CardTypeFormProps {}

export const CardTypeForm: FC<CardTypeFormProps> = () => {
  const [issuerOptions, setIssuerOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const cardType = useClassificationOptions(ClassificationGroup.CARD_TYPE);

  const fetchIssuerOptions = useCallback(async () => {
    setIssuerOptions(makeClassificationOptions(await fetchOrganizations()));
  }, []);

  useEffect(() => {
    fetchIssuerOptions();
  }, [fetchIssuerOptions]);

  return (
    <>
      <Grid item xs={1}>
        <TextField
          label={<TransField i18nKey="name" />}
          name="name"
          margin="dense"
          required
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransField i18nKey="issuer" />}
          name="issuer.id"
          options={issuerOptions}
          margin="dense"
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label={<TransField i18nKey="code" />}
          name="code"
          margin="dense"
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransField i18nKey="category" />}
          name="type.id"
          options={cardType}
          required
          margin="dense"
        />
      </Grid>
      <Grid item xs={1}>
        <RadioGroupField
          label={<TransField i18nKey="mandatoryCardNumber" />}
          name="isCardNumberMandatory"
          options="BOOL_ONLY"
          inline
          margin="dense"
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          label={<TransField i18nKey="orderNumber" />}
          type="number"
          name="orderNumber"
          margin="dense"
          required
        />
      </Grid>
    </>
  );
};
