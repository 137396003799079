import React from 'react';

export const useComponentWillUnmount = (
  cleanupCallback = () => {
    /**/
  }
) => {
  const callbackRef = React.useRef(cleanupCallback);
  callbackRef.current = cleanupCallback;
  React.useEffect(() => {
    return () => callbackRef.current();
  }, []);
};
