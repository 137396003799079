import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Layout, Loadable } from '@fleet/shared';
import { CardHeader } from '@fleet/shared/mui';
import { TransNav } from 'i18n/trans/header';
import { useSelector } from 'store/utils';
import { auditLoadingSelector } from 'features/loading/loadingSelectors';
import { AuditTable } from 'routes/audit/AuditTable';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'Audit',
  }
);

interface AuditProps {}

export const Audit: FC<AuditProps> = () => {
  const loading = useSelector(auditLoadingSelector);
  const classes = useStyles();

  return (
    <>
      <Layout
        className={classes.root}
        header={<CardHeader title={<TransNav i18nKey="audit" />} />}
      >
        <Loadable loading={loading}>
          <AuditTable />
        </Loadable>
      </Layout>
    </>
  );
};
