import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTab = createTrans({
  addresses: <Trans i18nKey="tab.addresses" defaults="Addresses" />,
  bankAccounts: <Trans i18nKey="tab.bankAccounts" defaults="Bank accounts" />,
  bookingAccessRights: (
    <Trans i18nKey="tab.bookingAccessRights" defaults="Booking access rights" />
  ),
  contactingOptions: (
    <Trans i18nKey="tab.contactingOptions" defaults="Contact options" />
  ),
  files: <Trans i18nKey="tab.files" defaults="Files" />,
  pointsOfSale: <Trans i18nKey="tab.pointsOfSale" defaults="Points of sales" />,
  serviceFees: <Trans i18nKey="tab.serviceFees" defaults="Service fees" />,
  stopCode: <Trans i18nKey="tab.stopCode" defaults="Codes" />,
  ticketTexts: <Trans i18nKey="tab.ticketTexts" defaults="Ticket texts" />,
  units: <Trans i18nKey="tab.units" defaults="Units" />,
});
