import { createSelector, selector } from 'store/utils';

export const selectAuditFilter = selector((state) => state.audit.filter);
export const auditFilterSelector = createSelector(selectAuditFilter);

export const selectAuditTrail = selector((state) => state.audit.search);
export const auditTrailSelector = createSelector(selectAuditTrail);

export const selectAuditTrailState = selector((state) => state.audit.current);
export const auditTrailStateSelector = createSelector(selectAuditTrailState);
