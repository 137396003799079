import { FC, useMemo, useCallback, ChangeEvent } from 'react';
import { FormProvider, useForm } from '@fleet/shared';
import {
  Checkbox,
  Table,
  TableColumns,
  useFormTable,
  useRowActive,
  useRowEditActions,
  useRowSelectCheckbox,
  useFormTableControls,
} from '@fleet/shared';
import { useRowSelect, useFilters } from 'react-table';
import { OrganizationUnit } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import {
  getOrganization,
  removeUnits,
  updateOrCreateUnit,
} from 'features/organizations/organizationActions';
import { useDispatch } from 'store/utils';
import { noop } from '@fleet/shared/utils/noop';
import { useHighLightAuditRow } from 'routes/organizations/hooks/useHighLightAuditRow';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface UnitsProps {
  data: Array<OrganizationUnit>;
}

export const Units: FC<UnitsProps> = ({ data }) => {
  const dispatch = useDispatch();

  const columns: TableColumns<OrganizationUnit> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '22vw',
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
        width: '60vw',
        editableProps: {
          required: false,
        },
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="active" />,
        type: 'checkbox',
        editableProps: {
          required: false,
        },
        width: '6vw',
      },
    ],
    []
  );
  const { form } = useForm<{ rows: Array<OrganizationUnit> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      data,
      columns,
      form,
      onRowUpdate: async (payload) => {
        await dispatch(updateOrCreateUnit(payload));
        await dispatch(getOrganization());
      },
    },
    useFilters,
    useRowSelect,
    useRowSelectCheckbox,
    useRowEditActions,
    useRowActive
  );
  useHighLightAuditRow(table, data);

  const handleActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isActive', e.target.checked),
    [table]
  );

  const onRowsRemove = useCallback(
    async (payload: Array<OrganizationUnit>) => {
      await dispatch(removeUnits(payload));
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Checkbox
            size="small"
            sx={{ flex: 1 }}
            label={<TransField i18nKey="activeUnits" />}
            onChange={handleActiveFilterToggle}
            inline
          />
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={() => addRow()}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          getTableProps={{ sx: { tableLayout: 'fixed' } }}
          getRowProps={() => ({
            sx: { backgroundColor: 'common.white' },
            onClick: noop,
          })}
          table={table}
        />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noUnitsFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
