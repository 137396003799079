import { createAsyncThunk } from 'store/utils';
import { Stop, StopPayload, StopSearchFilter } from 'dto/stop';
import { api } from 'features/api';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { Pagination } from '@fleet/shared/dto/pagination';

export const clearStop = createAction('clearStop');
export const setStopsFilter =
  createAction<Partial<StopSearchFilter>>('setStopsFilter');

export const getStopsList = createAsyncThunk<
  Pagination<Stop>,
  Partial<StopSearchFilter> | undefined
>('getStopsList', async (filter, { dispatch, getState }) => {
  const filterParams = filter || getState().stops.filter;
  filter && dispatch(setStopsFilter(filter));
  const result = (
    await api.get(
      `stops${qs.stringify(filterParams, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;
  return {
    ...result,
    limit: filterParams.limit ?? 10,
  };
});

export const createStop = createAsyncThunk<Stop, StopPayload>(
  'createStop',
  async (stopPayload) => (await api.post('/stops', stopPayload)).data
);

export const updateStop = createAsyncThunk<Stop, StopPayload>(
  'updateStop',
  async ({ id, ...stopPayload }) => {
    return (await api.put(`/stops/${id}`, stopPayload)).data;
  }
);

export const getStop = createAsyncThunk<Stop, string>('getStop', async (id) => {
  return (await api.get(`/stops/${id}`)).data;
});

export const deleteStop = createAsyncThunk<never, string>(
  'deleteStop',
  async (id) => {
    return await api.delete(`/stops/${id}`);
  }
);

export const deleteStops = createAsyncThunk<void, Array<string>>(
  'deleteSelectedStops',
  async (stopsIds) => {
    await Promise.all(stopsIds.map((id) => api.delete(`/stops/${id}`)));
  }
);

export const deleteLocalizationsStop = createAsyncThunk<void, Array<string>>(
  'deleteLocalizationsStop',
  async (stopLocalizationIds, { getState }) => {
    await Promise.all(
      stopLocalizationIds.map((cultureId) =>
        api.delete(
          `/stops/${getState().stops.current?.id}/localizations/${cultureId}`
        )
      )
    );
  }
);

export const createCode = createAsyncThunk<
  Stop,
  { stopId: string; codeListId: string; code: string }
>('createCode', async ({ stopId, codeListId, code }) => {
  return (await api.put(`/stops/${stopId}/codes/${codeListId}`, { code })).data;
});

export const deleteCode = createAsyncThunk<void, Array<string>>(
  'deleteCode',
  async (codeListIds, { getState }) => {
    await Promise.all(
      codeListIds.map((codeListId) =>
        api.delete(`stops/${getState().stops.current?.id}/codes/${codeListId}`)
      )
    );
  }
);
