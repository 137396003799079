import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  DateField,
  FormProvider,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Button, FormControl } from '@fleet/shared/mui';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  auditActionTypes,
  AuditSearchFormValues,
  auditTrailTypes,
} from 'dto/audit';
import { useDispatch, useSelector } from 'store/utils';
import { getAuditTrail } from 'features/audit/auditActions';
import { formSubmit } from 'helpers/formSubmit';
import { auditFilterSelector } from 'features/audit/auditSelectors';
import { renderToString } from 'react-dom/server';
import { flatten } from 'nest-deep';

interface AuditSearchFormProps {}

export const AuditSearchForm: FC<AuditSearchFormProps> = () => {
  const dispatch = useDispatch();
  const filter = useSelector(auditFilterSelector);

  const onSubmit = useCallback(
    (values: AuditSearchFormValues) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();

        await dispatch(
          getAuditTrail({
            ...values,
            limit: filter?.limit,
            offset: 0,
          })
        );
      }),
    [dispatch, filter]
  );
  const { form, handleSubmit, dirty, pristine, dirtySinceLastSubmit } =
    useForm<AuditSearchFormValues>({
      onSubmit,
      subscription: { dirty: true, pristine: true, dirtySinceLastSubmit: true },
    });
  useEffect(() => {
    if (filter && pristine && !dirtySinceLastSubmit) {
      Object.entries(flatten(filter)).forEach(([name, value]) => {
        form.change(name as keyof AuditSearchFormValues, value);
      });
    }
  }, [dirtySinceLastSubmit, filter, form, pristine]);
  const handleReset = useCallback(() => {
    form.reset({});
    dirty && form.submit();
  }, [dirty, form]);

  const auditTrailTypeOptions = useMemo(
    () =>
      auditTrailTypes.map((value) => ({
        value,
        label: renderToString(
          <TransField i18nKey={`auditTrailType.${value}`} />
        ),
      })),
    []
  );
  const auditActionTypeOptions = useMemo(
    () =>
      auditActionTypes.map((value) => ({
        value,
        label: renderToString(
          <TransField i18nKey={`auditActionType.${value}`} />
        ),
      })),
    []
  );

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <DateField
                name="validity"
                label={<TransField i18nKey="dateTimeRange" />}
                selectsRange
                showTimeInput
                isClearable
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="userName"
                label={<TransField i18nKey="username" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="auditTrailType"
                label={<TransField i18nKey="entity" />}
                options={auditTrailTypeOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="auditActionType"
                label={<TransField i18nKey="auditActionType" />}
                options={auditActionTypeOptions}
                showEmptyOption
              />
            </Grid>

            <Grid item xs={1}>
              <TextField name="id" label={<TransField i18nKey="id" />} />
            </Grid>

            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                    disabled={pristine}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
