import { ReactNode, useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { Column, useTable, useRowSelect } from 'react-table';
import { Table } from '@fleet/shared';
import { usePrevious, useRowSelectCheckbox } from '@fleet/shared/hooks';
import isEqual from 'lodash/isEqual';

interface UserFormOrganizationsSubRowTableData {
  isSelected?: boolean;
  id: string;
  name: string;
}

interface UserFormOrganizationsSubRowTableProps<
  D extends UserFormOrganizationsSubRowTableData
> {
  header: ReactNode;
  data: Array<D>;
  selected: Array<{ id: string }>;
  onUpdate: () => void;
  onAssign: (id: string) => void;
  onUnAssign: (id: string) => void;
}

const mapIds = <V extends { id: string }>(data: Array<V>) =>
  data.map(({ id }) => id);
export const UserFormOrganizationsSubRowTable = <
  D extends UserFormOrganizationsSubRowTableData
>({
  header,
  data,
  selected,
  onUpdate,
  onAssign,
  onUnAssign,
}: UserFormOrganizationsSubRowTableProps<D>) => {
  const initialSelectedIds = useMemo<Array<string>>(
    () => mapIds(selected),
    [selected]
  );
  const selectedRowIds = useMemo<Record<string, boolean>>(
    () =>
      data.reduce<{ [index: string]: boolean }>((acc, cur, idx) => {
        if (initialSelectedIds.includes(cur.id)) acc[idx] = true;
        return acc;
      }, {}),
    [data, initialSelectedIds]
  );
  const columns = useMemo<Array<Column<D>>>(
    () => [
      {
        accessor: 'name',
        Header: <>{header}</>,
        // @ts-ignore
        Cell: ({ value }) => value ?? '—',
      },
    ],
    [header]
  );

  const table = useTable(
    {
      data,
      columns,
      initialState: { selectedRowIds },
    },
    useRowSelect,
    useRowSelectCheckbox
  );

  const { selectedFlatRows } = table;
  const selectedIds = useMemo(
    () => selectedFlatRows.map(({ original }) => original.id),
    [selectedFlatRows]
  );

  const previouslySelectedIds = usePrevious(selectedIds);

  const handleUpdate = useCallback(async () => {
    const { 0: addedId } = selectedIds.filter(
      (x) => !previouslySelectedIds.includes(x)
    );
    const { 0: removedId } = previouslySelectedIds.filter(
      (x) => !selectedIds.includes(x)
    );
    if (addedId) await onAssign(addedId);
    if (removedId) await onUnAssign(removedId);
    await onUpdate();
  }, [onAssign, onUnAssign, onUpdate, previouslySelectedIds, selectedIds]);

  useEffect(() => {
    if (!data.length) return;
    if (!isEqual(previouslySelectedIds.sort(), selectedIds.sort())) {
      handleUpdate();
    }
  }, [data, handleUpdate, previouslySelectedIds, selectedIds]);

  return <Table table={table} />;
};
