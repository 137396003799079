import type { FC } from 'react';
import { Button } from '@mui/material';
import { Icon, CardHeader } from '@fleet/shared/mui';
import { useSelector } from 'store/utils';
import { Layout, Loadable } from '@fleet/shared';
import { UserForm } from 'routes/users/UserForm';
import { Link, Route, Switch } from 'react-router-dom';
import { UsersTable } from 'routes/users/UsersTable';
import { TransButton } from 'i18n/trans/button';
import { usersLoadingSelector } from 'features/loading/loadingSelectors';
import { TransNav } from 'i18n/trans/header';

interface UsersProps {}

export const Users: FC<UsersProps> = () => {
  const loading = useSelector(usersLoadingSelector);

  return (
    <>
      <Layout
        header={
          <CardHeader title={<TransNav i18nKey="users" />}>
            <Button
              startIcon={<Icon name="add" />}
              component={Link}
              to="/users/create"
            >
              <TransButton i18nKey="add" />
            </Button>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/users/:action(create|edit)/:id?"
              component={UserForm}
              exact
            />
          </Switch>
        }
      >
        <Loadable loading={loading}>
          <UsersTable />
        </Loadable>
      </Layout>
    </>
  );
};
