import { FC, useCallback, useEffect, useMemo } from 'react';
import { Typography, CardContent, Divider, Stack, Button } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { Table, TableColumns } from '@fleet/shared';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Stop } from 'dto/stop';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import {
  stopsFilterSelector,
  stopsSelector,
} from 'features/stops/stopsSelectors';
import { StopsSearchForm } from 'routes/stops/StopsSearchForm';
import { Icon } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { deleteStops, getStopsList } from 'features/stops/stopsActions';
import { PaginationParams } from '@fleet/shared/dto/pagination';

interface StopsTableProps {}

const useStyles = makeStyles(
  () => ({
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
  }),
  {
    name: 'StopsTable',
  }
);

export const StopsTable: FC<StopsTableProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const stops = useSelector(stopsSelector);
  const filter = useSelector(stopsFilterSelector);
  const { id } = useParams<{ action: 'create' | 'edit'; id?: string }>();
  const page = useMemo(() => {
    if (stops) {
      const { limit = 10, offset } = stops;
      return offset / limit;
    }
    return 0;
  }, [stops]);
  const data = useMemo(() => stops?.items ?? [], [stops]);
  const columns = useMemo<TableColumns<Stop>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="stopName" />,
        Cell: ({
          row: {
            original: { id, name },
          },
        }) => <Link to={`/stops/edit/${id}`}>{name}</Link>,
      },
      {
        id: 'address',
        accessor: (row) => row.address.country.name,
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        id: 'county',
        accessor: (row) => row.address.county,
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        id: 'city',
        accessor: (row) => row.address.city,
        Header: <TransTableHead i18nKey="cityParish" />,
      },
      {
        id: 'street',
        accessor: (row) => row.address.street,
        Header: <TransTableHead i18nKey="streetHouseNumber" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getStopsList({ ...filter, ...paginationParams })).unwrap(),
    [dispatch, filter]
  );
  const initialState = useMemo(() => ({ pageSize: 10, activeRowId: id }), [id]);
  const getRowId = useCallback((row: Stop) => row.id, []);
  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) =>
        useMemo(() => ({ ...state, pageIndex: page }), [state]),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: stops?.totalCount,
      getRowId,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );
  const {
    activeFlatRow,
    state: { selectedRowIds },
    resetRowActive,
  } = table;

  const selectedStopIds = useMemo(
    () => Object.keys(selectedRowIds),
    [selectedRowIds]
  );

  const removeSelectedStops = useCallback(async () => {
    await dispatch(deleteStops(selectedStopIds));
    dispatch(getStopsList());
  }, [dispatch, selectedStopIds]);

  useEffect(() => {
    if (activeFlatRow) {
      history.push(`/stops/edit/${activeFlatRow.original.id}`);
    }
  }, [activeFlatRow, history]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, dispatch, resetRowActive]);

  return (
    <Table
      classes={{
        cell: classes.tableCell,
      }}
      caption={
        <>
          <StopsSearchForm />

          <Divider />

          <CardContent>
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle" fontWeight="700">
                <TransSubtitle i18nKey="searchResults" />
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                <TransSubtitle
                  i18nKey="stopQty"
                  values={{ num: stops?.totalCount }}
                />
              </Typography>
              {!!selectedStopIds.length && (
                <>
                  <Button
                    startIcon={<Icon name="trash" />}
                    sx={{ ml: 'auto', px: 1, py: 0 }}
                    onClick={removeSelectedStops}
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                </>
              )}
            </Stack>
          </CardContent>
        </>
      }
      table={table}
    />
  );
};
