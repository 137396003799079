import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import type { TabsProps } from '@fleet/shared/mui/Tabs';
import { Loader, TabPanel, Tabs } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import {
  bookingAccessRightsSelector,
  OrganizationStopCodeSelector,
  selectCurrentOrganization,
  organizationFilesSelector,
} from 'features/organizations/organizationSelectors';
import { Units } from 'routes/organizations/Tabs/Units';
import { PointsOfSale } from 'routes/organizations/Tabs/PointsOfSale';
import { Addresses } from 'routes/organizations/Tabs/Addresses';
import { ContactOptions } from 'routes/organizations/Tabs/ContactOptions';
import { TicketTexts } from 'routes/organizations/Tabs/TicketTexts';
import { Box, Typography } from '@mui/material';
import { BookingAccessRights } from 'routes/organizations/Tabs/BookingAccessRights';
import { ServiceFees } from 'routes/organizations/Tabs/ServiceFees';
import { BankAccounts } from 'routes/organizations/Tabs/BankAccounts';
import { organizationsFormLoadingSelector } from 'features/loading/loadingSelectors';
import { StopFCode } from './Tabs/StopFCode';
import {
  getOrganizationFiles,
  getStopOrganization,
} from 'features/organizations/organizationActions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  OrganizationLocationState,
  OrganizationTab,
  organizationTabs,
} from 'dto/organization';
import { TransTab } from 'i18n/trans/tab';
import { Files } from 'routes/organizations/Tabs/Files';

const useStyles = makeStyles(
  (theme) => ({
    root: { padding: theme.spacing(3) },
  }),
  {
    name: 'OrganizationsDetails',
  }
);

export const OrganizationsDetails = () => {
  const organization = useSelector(selectCurrentOrganization);
  const { id } =
    useParams<{ id?: string; tab?: OrganizationTab; rowId?: string }>();
  const location = useLocation<OrganizationLocationState>();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(
    location.state?.tab ?? organizationTabs[0]
  );

  const history = useHistory();
  const handleTabChange = useCallback<Required<TabsProps>['onChange']>(
    (_, value) => {
      setTabValue(value);
      history.replace(location.pathname, { state: undefined });
    },
    [history, location.pathname]
  );

  useEffect(() => {
    dispatch(getStopOrganization(id!));
    dispatch(getOrganizationFiles({ organizationId: id! }));
  }, [dispatch, id]);

  const bookingAccessRights = useSelector(bookingAccessRightsSelector);
  const organizationLoading = useSelector(organizationsFormLoadingSelector);
  const organizationStopCodeList = useSelector(OrganizationStopCodeSelector);
  const organizationFiles = useSelector(organizationFilesSelector);

  const renderTab = useCallback(
    (name: OrganizationTab) => {
      if (!organization || organizationLoading)
        return (
          <Box sx={{ height: 150, position: 'relative' }}>
            <Loader active size="container" hideSpinner />
          </Box>
        );

      switch (name) {
        case 'units':
          return <Units data={organization[name]} />;
        case 'pointsOfSale':
          return <PointsOfSale data={organization[name]} />;
        case 'addresses':
          return <Addresses data={organization[name]} />;
        case 'contactingOptions':
          return <ContactOptions data={organization[name]} />;
        case 'serviceFees':
          return <ServiceFees data={organization[name]} />;
        case 'bookingAccessRights':
          return <BookingAccessRights data={bookingAccessRights} />;
        case 'stopCode':
          return (
            <StopFCode
              data={organizationStopCodeList!}
              orgId={organization.id}
            />
          );
        case 'bankAccounts':
          return <BankAccounts data={organization[name]} />;
        case 'ticketTexts':
          return <TicketTexts data={organization[name]} />;
        case 'files':
          return <Files />;
      }
    },
    [
      organizationLoading,
      organization,
      bookingAccessRights,
      organizationStopCodeList,
    ]
  );

  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable">
          {organizationTabs.map((tab) => (
            <TabPanel
              key={tab}
              label={
                organization && (
                  <>
                    <TransTab i18nKey={tab} />
                    &nbsp;
                    {tab === 'bookingAccessRights' ? (
                      bookingAccessRights ? (
                        <Typography component="span" variant="body2">
                          {`(${bookingAccessRights?.length})`}
                        </Typography>
                      ) : (
                        `(0)`
                      )
                    ) : tab === 'stopCode' ? (
                      organizationStopCodeList ? (
                        <Typography component="span" variant="body2">
                          {`(${organizationStopCodeList?.length})`}
                        </Typography>
                      ) : (
                        `(0)`
                      )
                    ) : tab === 'files' ? (
                      organizationFiles ? (
                        <Typography component="span" variant="body2">
                          {`(${organizationFiles?.length})`}
                        </Typography>
                      ) : (
                        <Typography component="span" variant="body2">
                          (0)
                        </Typography>
                      )
                    ) : organization[tab] ? (
                      <Typography
                        component="span"
                        variant="body2"
                      >{`(${organization[tab].length})`}</Typography>
                    ) : (
                      <Typography component="span" variant="body2">
                        (0)
                      </Typography>
                    )}
                  </>
                )
              }
              value={tab}
            >
              {renderTab(tab)}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </>
  );
};
