import { createSelector, selector } from 'store/utils';

export const selectCurrentUser = selector((state) => state.common.user);
export const currentUserSelector = createSelector(selectCurrentUser);

export const selectCurrentBusinessEntityId = selector(
  (state) => state.common.currentBusinessEntityId
);
export const currentBusinessEntityIdSelector = createSelector(
  selectCurrentBusinessEntityId
);
