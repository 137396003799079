import { FC, useCallback } from 'react';
import { Accordion, AccordionPanel } from '@fleet/shared';
import { Typography } from '@mui/material';
import { TransTitle, TransTitleKeys } from 'i18n/trans/title';
import { useSelector } from 'react-redux';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { CardType } from 'dto/cardType';
import { currentCardTypeSelector } from 'features/cardtypes/cardTypesSelectors';
import { CardTypeTranslations } from './CardTypeTranslations';

interface CardTypeAccordionProps {}

const panels: Array<{
  key: TransTitleKeys;
  Component: FC;
  field: keyof Pick<CardType, 'translations'>;
  isMandatory: boolean;
}> = [
  {
    key: 'translations',
    Component: CardTypeTranslations,
    isMandatory: false,
    field: 'translations',
  },
];

export const CardTypeAccordion: FC<CardTypeAccordionProps> = () => {
  const currentCardType = useSelector(currentCardTypeSelector);

  const renderSubtitle = useCallback(
    (field: keyof Pick<CardType, 'translations'>, isMandatory: boolean) => {
      if (!currentCardType) {
        return null;
      }

      return isMandatory && currentCardType[field]?.length === 0 ? (
        <TransSubtitle i18nKey="mandatory" />
      ) : (
        `(${currentCardType[field]?.length})`
      );
    },
    [currentCardType]
  );

  return (
    <Accordion controlled={false}>
      {panels.map(({ key, Component, field, isMandatory }) => (
        <AccordionPanel
          key={key}
          id={key}
          summary={
            <Typography variant="subtitle">
              <TransTitle i18nKey={key} />
              &nbsp;
              <Typography component="span" variant="body2">
                {renderSubtitle(field, isMandatory)}
              </Typography>
            </Typography>
          }
          disabled={!Component}
        >
          {currentCardType && (Component ? <Component /> : <span />)}
        </AccordionPanel>
      ))}
    </Accordion>
  );
};
