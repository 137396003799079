import type { FC } from 'react';
import { OrganizationsList } from 'routes/organizations/OrganizationsList';
import { OrganizationsCreate } from 'routes/organizations/OrganizationCreate';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OrganizationsEdit } from 'routes/organizations/OrganizationsEdit';

export interface OrganizationsTypesProps
  extends RouteComponentProps<{ typeId: string }> {}

export const Organizations: FC<OrganizationsTypesProps> = ({
  match: { path },
}) => (
  <Switch>
    <Route path={path} component={OrganizationsList} exact />
    <Route path={`${path}/create`} component={OrganizationsCreate} exact />
    <Route
      path={`${path}/:id/:tab?/:rowId?`}
      component={OrganizationsEdit}
      exact
    />
  </Switch>
);
