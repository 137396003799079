import type { FC } from 'react';
import { useCallback } from 'react';
import { Button } from '@mui/material';
import { Icon, Modal, useDrawerForm, useForm } from '@fleet/shared';
import { formSubmit } from 'helpers/formSubmit';
import { useDispatch, useSelector } from 'store/utils';
import { userSelector } from 'features/users/userSelectors';
import { api } from 'features/api';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { getUsers } from 'features/users/userActions';
import { useModal } from '@fleet/shared/hooks';
import { TransModal } from 'i18n/trans/modal';
import { TransAlert } from 'i18n/trans/alert';

interface UserPasswordResetModalProps {}

export const UserDeleteModal: FC<UserPasswordResetModalProps> = () => {
  const currentUser = useSelector(userSelector)!;

  const { open: isOpen, onOpen, onClose } = useModal();
  const alert = useAlert();
  const dispatch = useDispatch();
  const drawerForm = useDrawerForm();
  const onSubmit = useCallback(
    () =>
      formSubmit(async () => {
        await api.delete(`/users/${currentUser.id}`);
        drawerForm.handleClose();
        alert.success(<TransAlert i18nKey="userDeleted" />);
        dispatch(getUsers());
      }),
    [alert, currentUser.id, dispatch, drawerForm]
  );
  const { handleSubmit, submitting } = useForm({
    onSubmit,
    subscription: { submitting: true },
  });
  return (
    <>
      <Button size="small" color="error" onClick={onOpen}>
        <TransButton i18nKey="delete" />
      </Button>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={<TransModal i18nKey="deleteUser" />}
        actionButton={
          <form onSubmit={handleSubmit}>
            <Button
              type="submit"
              startIcon={<Icon name="trash" size={16} />}
              variant="contained"
              color="error"
              disabled={submitting}
            >
              <TransButton i18nKey="delete" />
            </Button>
          </form>
        }
        maxWidth="xs"
      >
        <TransModal
          i18nKey="userDeletionDescription"
          values={{ name: currentUser.username }}
        />
      </Modal>
    </>
  );
};
