import { createReducer } from '@reduxjs/toolkit';
import { CodeListItem } from 'dto/codeLists';
import { getCodeLists, setCodeList,updateCodeList } from './codeListsActions';

interface CodeListState {
  codeLists: Array<CodeListItem>;
  current?: CodeListItem;
}

const initialState: CodeListState = {
  codeLists: [],
};

export const codeListsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCodeLists.fulfilled, (state, action) => {
      state.codeLists = action.payload;
    })
    .addCase(setCodeList, (state, action) => {
      state.current = action.payload;
    }).addCase(updateCodeList.fulfilled, (state,action)=>{
        state.current = action.payload;
    });
});
