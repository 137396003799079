import { PaginationParams } from '@fleet/shared/dto/pagination';
import { StopCode } from 'dto/stop';
import { Classifier } from 'dto/classification';

export const organizationTabs = [
  'units',
  'pointsOfSale',
  'addresses',
  'contactingOptions',
  'serviceFees',
  'bookingAccessRights',
  'stopCode',
  'bankAccounts',
  'ticketTexts',
  'files',
] as const;

export type OrganizationTab = typeof organizationTabs[number];

export interface OrganizationLocationState {
  tab: OrganizationTab;
  entityId: string;
}

export interface Organization {
  id: string;
  name: string;
  brand: string;
  currency: {
    id: string;
    name: string;
  };
  operatingTimezone: string;
  isActive: boolean;
  registrationCode: string;
  vatRegistrationCode?: string;
  roles: Array<Classifier>;
  passwordPolicies: Array<Classifier>;
  contactingOptions: Array<{
    id: string;
    value: string;
    organizationUnitId: string;
    purposeType: Classifier;
    optionType: Classifier;
  }>;
  units: Array<OrganizationUnit>;
  pointsOfSale: Array<PointOfSale>;
  addresses: Array<OrganizationAddress>;
  bankAccounts: Array<OrganizationBankAccount>;
  ticketTexts: Array<OrganizationTicketText>;
  serviceFees: Array<ServiceFee>;
  organizationCodeList: Classifier;
  stopCodeList: Classifier;
  carrierOptions: {
    imsConfiguration: Classifier;
  };
}

export interface Price {
  currency: Classifier;
  maxAmount: number;
  minAmount: number;
}

export interface ServiceFee {
  id: string;
  pointOfSaleType: Classifier;
  prices: Array<Price>;
  type: Classifier;
  validity: { from: string; to: string };
  value: number;
  vatRate: number;
}
export interface ServiceFeePayload
  extends Omit<ServiceFee, 'pointOfSaleType' | 'type' | 'prices' | 'validity'> {
  pointOfSaleTypeId: string;
  typeId: string;
  validity: { from: string; to: string };
  prices?: Array<{
    currencyId: string;
    maxAmount: number;
    minAmount: number;
  }>;
}
export interface OrganizationUnit {
  id: string;
  name: string;
  code: string;
  isActive: boolean;
}

export interface UnitPayload extends Omit<OrganizationUnit, 'country'> {
  countryId: string;
}

export interface BookingAccessRight {
  id: string;
  organization: Classifier;
  retailer: Classifier;
  hasPermissionToView: boolean;
  hasPermissionToCancel: boolean;
  validity: { from: string; to: string };
}

export interface BookingAccessRightPayload
  extends Omit<BookingAccessRight, 'organization' | 'retailer' | 'validity'> {
  organizationId: string;
  validity: { from: string; to: string };
  retailerId: string;
}

export interface PointOfSale {
  id: string;
  name: string;
  isActive: boolean;
  toolType: Classifier;
  type: Classifier;
}

export interface PointsOfSalePayload
  extends Omit<PointOfSale, 'type' | 'toolType'> {
  typeId: string;
  toolType?: string | null;
}

export interface OrganizationAddress {
  city: string;
  country: Classifier;
  county: string;
  id: string;
  street: string;
  type: Classifier;
  zipCode: string;
  isActive: boolean;
  organizationUnitId: string;
}

export interface OrganizationAddressPayload
  extends Omit<OrganizationAddress, 'type' | 'country'> {
  typeId: string;
  countryId: string;
}

export interface ContactingOption {
  id: string;
  value: string;
  purposeType: {
    id: string;
    name: string;
  };
  optionType: {
    id: string;
    name: string;
  };
  organizationUnitId: string;
}

export interface ContactingOptionPayload
  extends Omit<OrganizationAddress, 'purposeType' | 'optionType'> {
  purposeTypeId: string;
  optionTypeId: string;
}

export interface OrganizationTabsDetails {
  addresses: Array<OrganizationAddress>;
  bankAccounts: Array<OrganizationBankAccount>;
  ticketTexts: Array<OrganizationTicketText>;
  contactingOptions: Array<ContactingOption>;
  bookingAccessRights: Array<BookingAccessRight>;
  serviceFees: Array<ServiceFee>;
  units: Array<OrganizationUnit>;
  stopCode: Array<StopCode>;
  files: Array<OrganizationFile>;
}

export type OrganizationDetails = Organization & OrganizationTabsDetails;

export interface OrganizationSearchFilter extends PaginationParams {
  Name: string;
  AssignedRoles?: Array<string>;
  RegistrationCode: string;
  IsActive?: boolean;
}

interface IdName {
  id: string;
  name: string;
}

export interface Application {
  id: string;
  name: string;
  clientId: string;
  clientSecret: string;
  isImmutable: Boolean;
  applicationUrl: string;
  organiztion: IdName;
  pointOfSale: IdName;
  unit: IdName;
}

export interface OrganizationBankAccount {
  id: string;
  name: string;
  type: Classifier;
  clearingNumber: string;
  accountNumber: string;
  iban: string;
  giroNumber: string;
  organizationUnitId: string;
}

export interface BankAccountPayload
  extends Omit<OrganizationBankAccount, 'type'> {
  typeId: string;
}

export interface OrganizationTicketText {
  id: string;
  text: string;
  language: Classifier;
  validityPeriod: { from: string; to: string };
  organizationUnitId: string;
}

export interface TicketTextPayload
  extends Omit<OrganizationTicketText, 'language'> {
  languageId: string;
}

export interface OrganizationFile {
  id: string;
  fileId: string;
  name: string;
  contentType: string;
  typeId: string;
}
