import { usePrevious } from '@fleet/shared/hooks';
import type { FC, MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { Row, Column, useTable, useExpanded } from 'react-table';
import { Icon, Table } from '@fleet/shared';
import { IconButton, Button } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { userSelector } from 'features/users/userSelectors';
import { GetSubRow } from '@fleet/shared/components/Table';
import { TransTableHead } from 'i18n/trans/table';
import { UserFormOrganizationsSubRow } from 'routes/users/UserFormOrganizationsSubRow';
import { joinDash } from 'helpers/array';
import { UserOrganizationsAddRelatedModal } from 'routes/users/modal/UserOrganizationsAddRelatedModal';
import { deleteUserOrganization, getUser } from 'features/users/userActions';
import { UserOrganization } from 'dto/user';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface UsersEditFormOrganizationsProps {}

export const UserFormOrganizations: FC<UsersEditFormOrganizationsProps> =
  () => {
    const currentUser = useSelector(userSelector);
    const currentUserId = currentUser?.id;
    const data = useMemo(() => currentUser?.organizations ?? [], [currentUser]);
    const dispatch = useDispatch();
    const alert = useAlert();
    const getRemoveHandler = useCallback(
      (row: Row<UserOrganization>) =>
        async (event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          event.currentTarget.disabled = true;
          const organizationId = event.currentTarget.id;
          try {
            await dispatch(
              deleteUserOrganization({ userId: currentUserId!, organizationId })
            ).unwrap();
            alert.success(<TransAlert i18nKey="organizationDeleted" />);
            row.isExpanded && row.toggleRowExpanded();
            await dispatch(getUser(currentUserId!));
          } catch (e) {
            event.currentTarget.disabled = false;
          }
        },
      [currentUserId, dispatch, alert]
    );
    const columns = useMemo<Array<Column<UserOrganization>>>(
      () => [
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="organization" />,
          Cell: (cell) => {
            const {
              row: { original, getToggleRowExpandedProps },
              value,
            } = cell;
            const { units, pointsOfSale } = original;
            const expandable = ![units.length, pointsOfSale.length].includes(0);
            if (!expandable) return value;
            return (
              <Button
                variant="text"
                color="inherit"
                size="small"
                sx={{ p: 0, minWidth: 0, fontSize: 'inherit' }}
                {...(expandable && getToggleRowExpandedProps())}
              >
                {expandable && <Icon name="chevron-down" />}
                {value}
              </Button>
            );
          },
          width: '33%',
        },
        {
          accessor: 'units',
          Header: <TransTableHead i18nKey="unit" />,
          Cell: ({ value }) => joinDash(value.map(({ name }) => name)),
          width: '33%',
        },
        {
          accessor: 'pointsOfSale',
          Header: <TransTableHead i18nKey="salesPoint" />,
          Cell: ({ value }) => joinDash(value.map(({ name }) => name)),
          width: '33%',
        },
        {
          accessor: 'id',
          Cell: ({ value, row }) => (
            <IconButton
              id={value}
              onClick={getRemoveHandler(row)}
              color="inherit"
            >
              <Icon name="trash" />
            </IconButton>
          ),
          width: 0,
        },
      ],
      [getRemoveHandler]
    );

    const prevData = usePrevious(data);
    const autoResetExpanded = useMemo(
      () => prevData.length !== data.length,
      [prevData, data]
    );
    const table = useTable(
      {
        data,
        columns,
        autoResetExpanded: autoResetExpanded, // prevent subRow close on data update
      },
      useExpanded
    );

    const getSubRow: GetSubRow<UserOrganization> = useCallback(
      (row) => <UserFormOrganizationsSubRow row={row} />,
      []
    );

    return (
      <Table
        table={table}
        caption={<UserOrganizationsAddRelatedModal />}
        getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
        getRowProps={{ sx: { cursor: 'pointer' } }}
        getSubRow={getSubRow}
      />
    );
  };
