import { createSelector, selector } from 'store/utils';

export const selectAlliancesList = selector((state) => state.alliances.list);
export const alliancesListSelector = createSelector(selectAlliancesList);

export const selectAlliance = selector((state) => state.alliances.current);
export const alliancesSelector = createSelector(selectAlliance);

export const selectCurrentAllianceId = selector(
  (state) => state.alliances.current!.id
);
