import { FC, useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory, useParams } from 'react-router-dom';
import { getCodeLists } from 'features/codelists/codeListsActions';
import { codeListsSelector } from 'features/codelists/codeListsSelectors';
import { codeListsLoadingSelector } from 'features/loading/loadingSelectors';
import { Layout, Loadable } from '@fleet/shared';
import { Button, Icon, CardHeader } from '@fleet/shared/mui';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { Box, CardContent, Grid, Drawer } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CodeListForm } from 'routes/configuration/codelists/CodeListForm';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      position: 'relative',
      height: '100%',
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      '& .MuiListItem-root': {
        color: 'inherit',
      },
      '& .MuiListItemText-secondary': {
        color: 'inherit',
      },

      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.1),
        '& $edit': {
          visibility: 'visible',
        },
      },
    },
    edit: {
      visibility: 'hidden',
      position: 'absolute',
      display: 'inline-flex',
      top: 5,
      right: 5,
      width: 24,
      height: 24,
      padding: 4,
      borderRadius: 3,
      background: theme.palette.common.white,
    },
    typog: {
      color: '#2C2C2C',
      fontWeight: 400,
    },
  }),
  {
    name: 'StopCodeLists',
  }
);
interface StopCodeListsProps {}

export const StopCodeLists: FC<StopCodeListsProps> = () => {
  const classes = useStyles();
  const loading = useSelector(codeListsLoadingSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isDrawerOpen = useMemo(
    () => Boolean(action) && (action === 'edit' ? !!id : true),
    [action, id]
  );
  useEffect(() => {
    dispatch(getCodeLists('stop'));
  }, [dispatch]);

  const stopCodeLists = useSelector(codeListsSelector);

  const handleCloseEditForm = useCallback(() => {
    history.replace('/stop-code-lists');
  }, [history]);

  return (
    <Loadable loading={loading}>
      <Layout
        sx={{ height: 'calc(100vh - 48px)' }}
        header={
          <CardHeader
            title={<TransTitle i18nKey="stopCodeList" />}
            sx={{ px: 3 }}
          >
            <Button
              variant="text"
              startIcon={<Icon name="add" />}
              onClick={() => history.push('/stop-code-lists/create')}
            >
              <TransButton i18nKey="add" />
            </Button>
          </CardHeader>
        }
        drawer={
          <Drawer
            elevation={0}
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseEditForm}
          >
            <CodeListForm />
          </Drawer>
        }
      >
        <CardContent sx={{ p: 3, flexGrow: 1 }}>
          <Grid container spacing={3}>
            {stopCodeLists.map(({ id, name, prefix }) => (
              <Grid key={id} item sm={4} md={3}>
                <Box
                  p={2}
                  component={Card}
                  variant="outlined"
                  className={classes.root}
                  square
                  onClick={() => history.push(`/stop-code-lists/edit/${id}`)}
                >
                  <span className={classes.edit}>
                    <Icon name="edit" />
                  </span>
                  <Typography
                    style={{ wordBreak: 'break-word', marginBottom: '16px' }}
                    variant="subtitle"
                    component="h3"
                    color="#000000"
                  >
                    {name}
                  </Typography>
                  <Typography
                    style={{ wordBreak: 'break-word', fontWeight: 400 }}
                    variant="subtitle"
                    component="h3"
                    color="text.secondary"
                  >
                    <TransTitle i18nKey="codeListPrefix" />
                  </Typography>
                  <Typography
                    style={{ wordBreak: 'break-word' }}
                    variant="subtitle"
                    component="h3"
                    className={classes.typog}
                  >
                    {prefix}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Layout>
    </Loadable>
  );
};
