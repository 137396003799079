import { createReducer } from '@reduxjs/toolkit';
import { Ims, ImsConfigurations } from 'dto/ims';
import {
  getImsConfigurations,
  updateIms,
  setIms,
} from 'features/ims/imsActions';
interface ImsState {
  imsConfigurations: ImsConfigurations;
  current?: Ims;
}

const initialState: ImsState = {
  imsConfigurations: [],
};

export const imsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getImsConfigurations.fulfilled, (state, action) => {
      state.imsConfigurations = action.payload;
    })
    .addCase(updateIms.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setIms, (state, action) => {
      state.current = action.payload;
    });
});
