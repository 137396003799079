import { CardHeader } from '@fleet/shared/mui';
import { Layout } from '@fleet/shared';
import { OrganizationForm } from 'routes/organizations/OrganizationForm';
import { TransSubtitle } from 'i18n/trans/subtitle';

export const OrganizationsCreate = () => {
  return (
    <div>
      <Layout
        header={
          <CardHeader title={<TransSubtitle i18nKey="newOrganization" />} />
        }
      >
        <OrganizationForm />
      </Layout>
    </div>
  );
};
