import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  activate: <Trans i18nKey="button.activate" defaults="Activate" />,
  add: <Trans i18nKey="button.add" defaults="Add" />,
  addEdit: <Trans i18nKey="button.addEdit" defaults="Add/edit" />,
  addNew: <Trans i18nKey="button.addNew" defaults="Add new" />,
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  changePassword: (
    <Trans i18nKey="button.changePassword" defaults="Change password" />
  ),
  close: <Trans i18nKey="button.close" defaults="Close" />,
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  copy: <Trans i18nKey="button.copy" defaults="Copy" />,
  create: <Trans i18nKey="button.create" defaults="Create" />,
  deactivate: <Trans i18nKey="button.deactivate" defaults="Deactivate" />,
  deactivateSelected: (
    <Trans i18nKey="button.deactivateSelected" defaults="Deactivate selected" />
  ),
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  export: <Trans i18nKey="button.export" defaults="Export" />,
  exportSelected: (
    <Trans i18nKey="button.exportSelected" defaults="Export selected" />
  ),
  resetChanges: (
    <Trans i18nKey="button.resetChanges" defaults="Reset changes" />
  ),
  resetFields: <Trans i18nKey="button.resetFields" defaults="Reset fields" />,
  resetFilters: (
    <Trans i18nKey="button.resetFilters" defaults="Reset filters" />
  ),
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  searchable: (
    <Trans
      i18nKey="button.searchable"
      defaults="Searchable stops appear for the end users in sales search results and can be bought tickets to"
    />
  ),
  sendPasswordResetLink: (
    <Trans
      i18nKey="button.sendPasswordResetLink"
      defaults="Send password reset link"
    />
  ),
  view: <Trans i18nKey="button.view" defaults="View" />,
});
