import get from 'lodash/get';
import { serializeErrors } from 'helpers/serializeErrors';

interface Action<D> {
  (): Promise<D>;
}
export async function formSubmitThrowError<D>(action: Action<D>) {
  try {
    await action();
  } catch (error) {
    const status = get(error, 'response.status');
    if (status && status >= 400 && status <= 500) throw serializeErrors(error);
    return {};
  }
}

export async function formSubmit<D>(action: Action<D>) {
  try {
    await formSubmitThrowError(action);
  } catch (errors) {
    return errors;
  }
}
