import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { useCallback, useEffect } from 'react';
import {
  Button,
  CheckboxGroupField,
  FormControl,
  FormProvider,
  RadioGroupField,
  SearchForm,
  TextField,
  useForm,
  useIsMounted,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { OrganizationSearchFilter } from 'dto/organization';
import {
  cleanOrganizationsSearch,
  getOrganizationsList,
} from 'features/organizations/organizationActions';
import { useDispatch, useSelector } from 'store/utils';
import { organizationsFilterSelector } from 'features/organizations/organizationSelectors';
import { formSubmit } from 'helpers/formSubmit';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';

const useStyles = makeStyles(
  () => ({
    searchContent: {
      padding: '4px 8px',
    },
  }),
  {
    name: 'OrganizationSearchForm',
  }
);

export const OrganizationSearchForm = () => {
  const dispatch = useDispatch();
  const filter = useSelector(organizationsFilterSelector);
  const roles = useClassificationOptions(ClassificationGroup.ORGANIZATION_ROLE);
  const onSubmit = (formFilter: OrganizationSearchFilter) =>
    formSubmit(async () => {
      (document.activeElement as HTMLInputElement)?.blur?.();
      await dispatch(
        getOrganizationsList({ limit: filter?.limit, ...formFilter })
      );
    });

  const { form, handleSubmit, dirty } = useForm<OrganizationSearchFilter>({
    onSubmit,
    subscription: { dirty: true },
  });
  const { current: isMounted } = useIsMounted();
  useEffect(() => {
    // reset filter on back to search from detailed view
    if (!isMounted && filter) {
      form.mutators.change(filter);
    }
  }, [filter, form, isMounted]);

  useComponentWillUnmount(() => {
    dispatch(cleanOrganizationsSearch());
  });

  const handleReset = useCallback(() => {
    form.reset({});
    dirty && form.submit();
  }, [form, dirty]);

  const classes = useStyles();
  return (
    <SearchForm
      className={classes.searchContent}
      title={<TransSubtitle i18nKey="search" />}
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2}>
            <Grid item xs={1}>
              <TextField name="Name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="RegistrationCode"
                label={<TransField i18nKey="registrationCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <CheckboxGroupField
                options={roles}
                name="AssignedRoles"
                label={<TransField i18nKey="roles" />}
                inline
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                name="IsActive"
                label={<TransField i18nKey="isActive" />}
                options="BOOL"
                inline
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button onClick={handleReset} variant="text">
                    <TransButton i18nKey="resetFields" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button icon="search" type="submit" sx={{ ml: 2 }}>
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
