import { createAsyncThunk } from 'store/utils';
import { api } from 'features/api';
import { CodeListItem } from 'dto/codeLists';
import { createAction } from '@reduxjs/toolkit';

export const getCodeLists = createAsyncThunk<Array<CodeListItem>, string>(
  'codeLists/get-codeLists',
  async (type: string) =>
    (await api.get(`/code-lists/${type}?limit=1000`)).data.items
);

export const setCodeList = createAction<CodeListItem | undefined>(
  'codeLists/setcodeList'
);

///code-lists/{type}/{id}
export const getCodeListById = createAsyncThunk<
  CodeListItem,
  { id: string; type: string }
>('codeList/getById', async ({ id, type }, store) => {
  const codeList = (await api.get(`/code-lists/${type}/${id}`)).data;
  store.dispatch(setCodeList(codeList));
  return codeList;
});
///code-lists/{type}
export const createCodeList = createAsyncThunk<CodeListItem, { t: string }>(
  'codeLists/create',
  async ({ t, ...payload }, { rejectWithValue }) => {
    try {
      return (await api.post(`/code-lists/${t}`, payload)).data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
///code-lists/{type}/{id}
export const updateCodeList = createAsyncThunk<
  CodeListItem,
  { id: string; type: string }
>('codeLists/update', async ({ id, type, ...payload }, { rejectWithValue }) => {
  try {
    return (await api.put(`/code-lists/${type}/${id}`, payload)).data;
  } catch (e) {
    rejectWithValue(e);
  }
});

export const deleteCodeList = createAsyncThunk<
  void,
  { id: string; type: string }
>('codeLists/delete', async ({ id, type }) => {
  await api.delete(`/code-lists/${type}/${id}`);
});
