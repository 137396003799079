import { FC, useCallback, useEffect, useMemo } from 'react';
import { Loadable, FormProvider, useForm } from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import { Tooltip, Icon } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { imsLoadingSelector } from 'features/loading/loadingSelectors';
import { useHistory, useParams } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { imsSelector } from 'features/ims/imsSelectors';
import { Ims } from 'dto/ims';
import { Scopes } from 'routes/configuration/ims/Scopes';
import {
  createIms,
  getImsConfigurations,
  updateIms,
  getImsById,
  setIms,
} from 'features/ims/imsActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

const useStyles = makeStyles(
  (theme) => ({
    formFields: {
      '&& > .MuiFormControl-root': {
        '&:not($formSwitch)': {
          marginBottom: 16,
        },

        '& > label': {
          color: theme.palette.text.primary,
          fontSize: 14,
          flexGrow: 1,
        },
      },
    },
    formSwitch: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      '& .Icon-root': {
        pointerEvents: 'all',
      },
    },
  }),
  {
    name: 'ImsForm',
  }
);

interface ImsFormProps {}

export const ImsForm: FC<ImsFormProps> = () => {
  const dispatch = useDispatch();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  useEffect(() => {
    dispatch(setIms());
    if (action === 'edit' && id) {
      dispatch(getImsById(id));
    }
    return () => {
      dispatch(setIms());
    };
  }, [action, dispatch, id]);
  const currentIms = useSelector(imsSelector);
  const classes = useStyles();
  const loading = useSelector(imsLoadingSelector);

  const history = useHistory();

  const closeDrawer = useCallback(() => {
    history.replace('/ims');
  }, [history]);

  const alert = useAlert();
  const onSubmit = useCallback(
    async (values: Ims) => {
      if (id) {
        await dispatch(updateIms(values)).unwrap();
        if (currentIms) {
          alert.success(<TransAlert i18nKey="imsSaved" />);
          closeDrawer();
        }
      } else {
        const { id } = await dispatch(createIms(values)).unwrap();

        if (id) {
          alert.success(<TransAlert i18nKey="imsCreated" />);
          closeDrawer();
        }
      }
      await dispatch(getImsConfigurations());
    },

    [alert, currentIms, closeDrawer, dispatch, id]
  );

  const initialValues = useMemo(() => ({ ...currentIms }), [currentIms]);

  const { form, handleSubmit } = useForm<Ims>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });
  const { reset } = form;

  useEffect(() => {
    if (!currentIms) {
      reset({});
    }
  }, [currentIms, reset]);

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Stack
          sx={{
            width: 400,
            height: '100%',
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <CardHeader
            sx={{ px: 3, py: 2 }}
            title={
              <Typography variant="subtitle">
                <TransTitle i18nKey="ims" />
              </Typography>
            }
            action={
              <IconButton aria-label="close" onClick={closeDrawer}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent
            sx={{ px: 3, py: 2, flex: 1, overflowY: 'scroll' }}
            className={classes.formFields}
          >
            <TextField
              label={<TransField i18nKey="name" />}
              name="name"
              required
            />
            <TextField
              label={<TransField i18nKey="apiEndpoint" />}
              name="apiEndpoint"
              required
            />
            <TextField
              label={<TransField i18nKey="globalTimeouts" />}
              name="timeouts.global"
              placeholder="HH:MM:SS"
              required
            />
            <Typography
              variant="subtitle"
              component="h3"
              sx={{ padding: '16px 0 8px' }}
            >
              <TransTitle i18nKey="token" />
            </Typography>
            <TextField
              label={<TransField i18nKey="authorityEndpoint" />}
              name="tokenAuthentication.authorityEndpoint"
              required
            />
            <TextField
              label={<TransField i18nKey="issuer" />}
              name="tokenAuthentication.issuer"
              required
            />
            <TextField
              label={<TransField i18nKey="clientId" />}
              name="tokenAuthentication.clientId"
              required
            />
            <TextField
              label={<TransField i18nKey="clientSecret" />}
              name="tokenAuthentication.clientSecret"
              required
            />
            <Scopes />
          </CardContent>
          <CardActions
            sx={{
              padding: 3,
              justifyContent: 'flex-end',
              boxShadow: 2,
            }}
          >
            <Button variant="text" color="primary" onClick={closeDrawer}>
              <TransButton i18nKey="cancel" />
            </Button>
            {id ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Icon name="check" />}
              >
                <TransButton i18nKey="save" />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="add" />
              </Button>
            )}
          </CardActions>
        </Stack>
      </FormProvider>
    </Loadable>
  );
};
