import { CardType } from 'dto/cardType';
import { createReducer } from '@reduxjs/toolkit';
import { getCardTypes, setCardType } from './cardTypesActions';

interface CardTypeState {
  cardTypes: Array<CardType>;
  current?: CardType;
}

const initialState: CardTypeState = {
  cardTypes: [],
};

export const cardTypesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCardTypes.fulfilled, (state, action) => {
      state.cardTypes = action.payload;
    })
    .addCase(setCardType, (state, action) => {
      state.current = action.payload;
    });
});
