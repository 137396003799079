import { api } from 'features/api';
import { Classifier } from 'dto/classification';
import qs from 'qs';

export const fetchOrganizations = async () =>
  (
    await api.get<{ items: Array<Classifier> }>(
      `organizations?${qs.stringify({
        limit: 1000,
      })}`
    )
  ).data.items;
