import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import {
  FormProvider,
  useForm,
  Checkbox,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
} from '@fleet/shared';
import { useFilters, useRowSelect } from 'react-table';
import { ServiceFee } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { useDispatch } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getOrganization,
  removeServiceFees,
  updateOrCreateServiceFee,
} from 'features/organizations/organizationActions';
import { Icon } from '@fleet/shared/mui';
import { formatDate, parseISODateString } from '@fleet/shared/utils/date';
import { FeePricesCell } from './FeePricesCell';
import { isBefore, isAfter, parseISO } from 'date-fns';
import { useFormTableControls } from '@fleet/shared/hooks';
import { useHighLightAuditRow } from 'routes/organizations/hooks/useHighLightAuditRow';
import makeStyles from '@mui/styles/makeStyles';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface ServiceFeesProps {
  data: Array<ServiceFee>;
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflow: 'initial',
  },
  cell: {
    padding: '5px 0 !important' as string,
    verticalAlign: 'inherit',
  },
}));

export const ServiceFees: FC<ServiceFeesProps> = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const types = useClassificationOptions(
    ClassificationGroup.RETAILER_SERVICE_FEE_TYPE
  );
  const pointOfSaleTypes = useClassificationOptions(
    ClassificationGroup.POINT_OF_SALE_TYPE
  );
  const { form } = useForm<{ rows: Array<ServiceFee> }>({
    initialValues: {
      rows: data,
    },
  });
  const isValidAccessor = useCallback(
    (row: { validity: { from: string; to?: string } }) => {
      if (!row?.validity) return;
      const { from, to } = row.validity;
      const now = Date.now();
      const [validFromDate, validToDate] = [from, to]
        .filter(Boolean)
        .map((str) => parseISO(str!));
      const validityStarted = isAfter(now, validFromDate);
      const validityActive = validToDate ? isBefore(now, validToDate) : true;
      return validityStarted && validityActive;
    },
    []
  );

  const columns: TableColumns<ServiceFee> = useMemo(
    () => [
      { id: 'id' },
      {
        id: 'isValid',
        accessor: isValidAccessor,
      },
      {
        id: 'type.id',
        accessor: ({ type }) => type?.id,
        Header: <TransTableHead i18nKey="type" />,
        type: 'select',
        editableProps: { options: types },
        width: '15vw',
      },
      {
        id: 'pointOfSaleType.id',
        accessor: ({ pointOfSaleType }) => pointOfSaleType?.id,
        Header: <TransTableHead i18nKey="pointsOfSales" />,
        type: 'select',
        width: '15vw',
        editableProps: { options: pointOfSaleTypes },
      },
      {
        accessor: 'value',
        Header: <TransTableHead i18nKey="value" />,
        width: '8vw',
        editableProps: {
          positive: true,
        },
      },
      {
        accessor: 'vatRate',
        Header: <TransTableHead i18nKey="vatRate" />,
        width: '8vw',
        editableProps: {
          positive: true,
        },
      },
      {
        accessor: 'prices',
        Header: <TransTableHead i18nKey="thresholds" />,
        // overwrite TableCellEditable
        Cell: FeePricesCell,
        width: '14vw',
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) => formatDate(validity?.from),
        Header: <TransTableHead i18nKey="validFrom" />,
        type: 'date',
        width: '14vw',
        editableProps: {
          minDate: () => Date.now(),
        },
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) => formatDate(validity?.to),
        Header: <TransTableHead i18nKey="validTo" />,
        type: 'date',
        width: '14vw',
        conditions: {
          // disable field until validity.to is filled
          disabled: {
            when: 'validity.from', // adds field to watched value
            is: '',
          },
        },
        editableProps: {
          required: false,
          // @ts-ignore
          minDate: (values) => parseISODateString(values['validity.from']),
        },
      },
    ],
    [types, pointOfSaleTypes, isValidAccessor]
  );

  const table = useFormTable(
    {
      data,
      columns,
      form,
      initialState: {
        hiddenColumns: ['id', 'isValid'],
      },
      onRowUpdate: async ({
        pointOfSaleType,
        type,
        prices,
        validity,
        ...payload
      }) => {
        await dispatch(
          updateOrCreateServiceFee({
            ...payload,
            typeId: type.id,
            pointOfSaleTypeId: pointOfSaleType.id,
            prices: prices?.map(({ currency, ...payload }) => ({
              ...payload,
              currencyId: currency.id,
            })),
            validity: { from: validity.from, to: validity.to },
          })
        ).unwrap();
        await dispatch(getOrganization());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowActive
  );
  useHighLightAuditRow(table, data);

  const handleValidFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isValid', e.target.checked || undefined),
    [table]
  );

  const onRowsRemove = useCallback(
    async (payload: Array<ServiceFee>) => {
      await dispatch(removeServiceFees(payload));
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Checkbox
            size="small"
            sx={{ flex: 1 }}
            label={<TransField i18nKey="validServiceFee" />}
            onChange={handleValidFilterToggle}
            inline
          />
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          getTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          table={table}
          classes={{
            container: classes.tableContainer,
            cell: classes.cell,
          }}
        />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noServiceFeesFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
