import { FC } from 'react';
import { useMemo, useCallback } from 'react';
import { useRowSelect } from 'react-table';
import { Icon, Table, TableColumns, useField } from '@fleet/shared';
import { Box, Button, Stack } from '@mui/material';
import {
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
  useFormTable,
  useFormTableControls,
} from '@fleet/shared/hooks';
import { FormProvider, useForm } from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { StopLocalization } from 'dto/stop';
import { useSelector } from 'store/utils';
import { currentStopSelector } from 'features/stops/stopsSelectors';
import { useDispatch } from 'react-redux';
import {
  deleteLocalizationsStop,
  getStop,
  updateStop,
} from 'features/stops/stopsActions';

interface StopLocalizationsTableProps {
  stopId: string;
}

export const StopLocalizationsTable: FC<StopLocalizationsTableProps> = ({
  stopId,
}) => {
  const languages = useClassificationOptions(ClassificationGroup.CULTURE);
  const dispatch = useDispatch();
  const currentStop = useSelector(currentStopSelector)!;
  const {
    input: { onChange },
  } = useField('localizations', { subscription: {} });

  const { form } = useForm<{ rows: Array<StopLocalization> }>({
    initialValues: {
      rows: currentStop.localizations,
    },
  });

  const columns = useMemo<TableColumns<StopLocalization>>(
    () => [
      {
        accessor: 'cultureId',
        type: 'select',
        editableProps: {
          options: languages,
        },
        Header: <TransTableHead i18nKey="language" />,
        width: '40%',
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '60%',
      },
    ],
    [languages]
  );

  const formTable = useFormTable<StopLocalization>(
    {
      data: currentStop.localizations,
      columns,
      form,
      onRowUpdate: (_, values) => {
        handleUpdateRows(values);
      },
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (localizationList: Array<StopLocalization>) => {
      const stopLocalizationIds = localizationList.map(
        ({ cultureId }) => cultureId
      );

      await dispatch(deleteLocalizationsStop(stopLocalizationIds));
      dispatch(getStop(stopId));
    },
    [dispatch, stopId]
  );

  const handleUpdateRows = useCallback(
    async (values) => {
      onChange(values);
      const payload = {
        ...currentStop,
        address: {
          ...currentStop.address,
          countryId: currentStop.address.country.id,
        },
        localizations: values,
      };

      await dispatch(updateStop(payload));
    },
    [currentStop, dispatch, onChange]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table: formTable,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          getHeaderGroupProps={{
            sx: {
              background: 'white',
            },
          }}
          table={formTable}
        />
      </Box>
    </FormProvider>
  );
};
