import { createReducer } from '@reduxjs/toolkit';
import {
  getCurrentUser,
  setCurrentBusinessEntity,
} from 'features/common/commonActions';
import { User } from 'dto/user';

export interface CommonState {
  user: User;
  currentBusinessEntityId: string;
}

const initialState = {
  user: {},
  currentBusinessEntityId: localStorage.getItem('businessEntityId') || '',
} as CommonState;

export const commonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload;
    })
    .addCase(setCurrentBusinessEntity, (state, action) => {
      localStorage.setItem('businessEntityId', String(action.payload));
      state.currentBusinessEntityId = action.payload;
    });
});
