import type { FC } from 'react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from '@fleet/shared/hooks';
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { Icon, Modal } from '@fleet/shared';
import { FormProvider, useForm } from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import { useSelector } from 'store/utils';
import { userSelector } from 'features/users/userSelectors';
import { api } from 'features/api';
import { useAlert } from 'react-alert';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { formSubmit } from 'helpers/formSubmit';
import { TransModal } from 'i18n/trans/modal';
import { TransAlert } from 'i18n/trans/alert';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransValidate } from 'i18n/trans/validate';
import {
  PASSWORD_REQUIREMENTS,
  PasswordRequirements,
  useChangePassword,
} from 'hooks/useChangePassword';

interface UserPasswordModalProps {}

interface UserPasswordFormValues {
  password?: string;
}

export const UserPasswordChangeModal: FC<UserPasswordModalProps> = () => {
  const [showPassword, setShowPassword] = useState(false);

  const currentUser = useSelector(userSelector)!;

  const { open: isOpen, onOpen, onClose } = useModal();
  const alert = useAlert();

  const {
    getTransValidateKey,
    filterMissingPasswordRequirements,
    isPasswordHelperShown,
  } = useChangePassword();

  const handleValidatePassword = useCallback(
    (value?: string) => {
      const key = getTransValidateKey(value);

      if (key) {
        return <TransValidate i18nKey={key} />;
      }
    },
    [getTransValidateKey]
  );

  const onSubmit = useCallback(
    (data: UserPasswordFormValues) =>
      formSubmit(async () => {
        await api.post(`/users/${currentUser.id}/change-password`, data);
        onClose();
        alert.success(<TransAlert i18nKey="passwordChanged" />);
      }),
    [alert, currentUser.id, onClose]
  );

  const { form, handleSubmit, values, touched, hasValidationErrors } =
    useForm<UserPasswordFormValues>({
      onSubmit,
      subscription: { values: true, touched: true, hasValidationErrors: true },
    });

  const missingPasswordRequirements = useMemo(
    () => filterMissingPasswordRequirements(values.password),
    [filterMissingPasswordRequirements, values.password]
  );

  const renderPasswordRequirementsTransFields = useCallback(
    (requirements: readonly PasswordRequirements[]) =>
      requirements.map((requirement, index) => (
        <Fragment key={requirement}>
          <TransField i18nKey={requirement} />
          {index < requirements.length - 1 && ', '}
        </Fragment>
      )),
    []
  );

  useEffect(() => {
    if (!isOpen) {
      form.reset({});
      setShowPassword(false);
    }
  }, [form, isOpen]);

  return (
    <>
      <Button
        startIcon={<Icon name="replace" size={16} />}
        size="small"
        onClick={onOpen}
      >
        <TransButton i18nKey="changePassword" />
      </Button>
      <Modal
        open={isOpen}
        title={<TransModal i18nKey="changePassword" />}
        onClose={onClose}
        actionButton={
          <Button
            variant="contained"
            startIcon={<Icon name="check" size={16} />}
            onClick={() => handleSubmit()}
          >
            <TransButton i18nKey="confirm" />
          </Button>
        }
        maxWidth="xs"
      >
        <FormProvider {...form}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label={<TransField i18nKey="typeInNewPassword" />}
                helper={
                  isPasswordHelperShown(
                    values?.password,
                    touched?.password,
                    hasValidationErrors
                  ) && <TransValidate i18nKey="passwordMinimalLength" />
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      size="small"
                      edge="end"
                    >
                      <Icon
                        name={showPassword ? 'visibility-on' : 'visibility-off'}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                margin="normal"
                validate={handleValidatePassword}
              />
            </Grid>
            <Grid item xs={12}>
              <Card variant="surface">
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant="body2" component="div">
                      <Typography fontWeight={700} component="div">
                        <TransSubtitle i18nKey="passwordRequirement" />
                      </Typography>
                      {renderPasswordRequirementsTransFields(
                        PASSWORD_REQUIREMENTS
                      )}
                    </Typography>
                    {!!missingPasswordRequirements.length && (
                      <Typography variant="body2" component="div">
                        <Typography fontWeight={700} component="div">
                          <TransSubtitle i18nKey="passwordMissingRequirement" />
                        </Typography>
                        {renderPasswordRequirementsTransFields(
                          missingPasswordRequirements
                        )}
                      </Typography>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
};
