import Card from '@mui/material/Card';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useEffect, useCallback, useMemo, FC } from 'react';
import { Button, Icon, CardHeader } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { imsLoadingSelector } from 'features/loading/loadingSelectors';
import { Box, CardContent, Grid, Drawer } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { Layout } from '@fleet/shared/components/Layout';
import { Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { imsConfigurationsSelector } from 'features/ims/imsSelectors';
import { getImsConfigurations } from 'features/ims/imsActions';
import { ImsForm } from 'routes/configuration/ims/ImsForm';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      position: 'relative',
      height: '100%',
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      '& .MuiListItem-root': {
        color: 'inherit',
      },
      '& .MuiListItemText-secondary': {
        color: 'inherit',
      },

      '&:hover': {
        background: alpha(theme.palette.primary.main, 0.1),
        '& $edit': {
          visibility: 'visible',
        },
      },
    },
    edit: {
      visibility: 'hidden',
      position: 'absolute',
      display: 'inline-flex',
      top: 5,
      right: 5,
      width: 24,
      height: 24,
      padding: 4,
      borderRadius: 3,
      background: theme.palette.common.white,
    },
  }),
  {
    name: 'Ims',
  }
);

interface ImsProps {}

export const Ims: FC<ImsProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(imsLoadingSelector);
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isDrawerOpen = useMemo(
    () => Boolean(action) && (action === 'edit' ? !!id : true),
    [action, id]
  );
  useEffect(() => {
    dispatch(getImsConfigurations());
  }, [dispatch]);

  const imsConfigurations = useSelector(imsConfigurationsSelector);

  const handleCloseEditForm = useCallback(() => {
    history.replace('/ims');
  }, [history]);
  return (
    <Loadable loading={loading}>
      <Layout
        sx={{ height: 'calc(100vh - 48px)' }}
        header={
          <CardHeader title={<TransTitle i18nKey="imsList" />} sx={{ px: 3 }}>
            <Button
              variant="text"
              startIcon={<Icon name="add" />}
              onClick={() => history.push('/ims/create')}
            >
              <TransButton i18nKey="add" />
            </Button>
          </CardHeader>
        }
        drawer={
          <Drawer
            elevation={0}
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseEditForm}
          >
            <ImsForm />
          </Drawer>
        }
      >
        <CardContent sx={{ p: 3, flexGrow: 1 }}>
          <Grid container spacing={3}>
            {imsConfigurations.map(({ id, name }) => (
              <Grid key={id} item sm={4} md={3}>
                <Box
                  p={2}
                  component={Card}
                  variant="outlined"
                  className={classes.root}
                  square
                  onClick={() => history.push(`/ims/edit/${id}`)}
                >
                  <span className={classes.edit}>
                    <Icon name="edit" />
                  </span>
                  <Typography
                    style={{
                      wordBreak: 'break-word',
                    }}
                    variant="subtitle"
                    component="h3"
                    color="text.primary"
                  >
                    {name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Layout>
    </Loadable>
  );
};
