import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  activeAddresses: (
    <Trans
      i18nKey="field.activeAddresses"
      defaults="Show only active addresses"
    />
  ),
  activePointsOfSales: (
    <Trans
      i18nKey="field.activePointsOfSales"
      defaults="Show only active points of sales"
    />
  ),
  activeUnits: (
    <Trans i18nKey="field.activeUnits" defaults="Show only active units" />
  ),
  apiEndpoint: <Trans i18nKey="field.apiEndpoint" defaults="API endpoint" />,
  assigned: <Trans i18nKey="field.assigned" defaults="Assigned" />,
  auditActionType: (
    <Trans i18nKey="field.auditActionType.label" defaults="Event type" />
  ),
  'auditActionType.insert': (
    <Trans i18nKey="field.auditActionType.option.insert" defaults="Insert" />
  ),
  'auditActionType.delete': (
    <Trans i18nKey="field.auditActionType.option.delete" defaults="Delete" />
  ),
  'auditActionType.update': (
    <Trans i18nKey="field.auditActionType.option.update" defaults="Update" />
  ),
  auditTrailType: (
    <Trans i18nKey="field.auditTrailType.label" defaults="Entity" />
  ),
  'auditTrailType.organization': (
    <Trans
      i18nKey="field.auditTrailType.option.organization"
      defaults="Organization"
    />
  ),
  'auditTrailType.organization-contacting-option': (
    <Trans
      i18nKey="field.auditTrailType.option.organization-contacting-option"
      defaults="Organization / Contacting option"
    />
  ),
  'auditTrailType.organization-pointOfSale': (
    <Trans
      i18nKey="field.auditTrailType.option.organization-pointOfSale"
      defaults="Organization / Point of sale"
    />
  ),
  'auditTrailType.organization-retailer-service-fee': (
    <Trans
      i18nKey="field.auditTrailType.option.organization-retailer-service-fee"
      defaults="Organization / Retailer service fee"
    />
  ),
  'auditTrailType.organization-unit': (
    <Trans
      i18nKey="field.auditTrailType.option.organization-unit"
      defaults="Organization / Unit"
    />
  ),
  'auditTrailType.alliance': (
    <Trans i18nKey="field.auditTrailType.option.alliance" defaults="Alliance" />
  ),
  'auditTrailType.code-list': (
    <Trans
      i18nKey="field.auditTrailType.option.code-list"
      defaults="Code list"
    />
  ),
  'auditTrailType.ims-configuration': (
    <Trans
      i18nKey="field.auditTrailType.option.ims-configuration"
      defaults="Ims configuration"
    />
  ),
  'auditTrailType.stop': (
    <Trans i18nKey="field.auditTrailType.option.stop" defaults="Stop" />
  ),
  'auditTrailType.template': (
    <Trans i18nKey="field.auditTrailType.option.template" defaults="Template" />
  ),
  'auditTrailType.user': (
    <Trans i18nKey="field.auditTrailType.option.user" defaults="User" />
  ),
  authorityEndpoint: (
    <Trans
      i18nKey="field.tokenAuthentication.authorityEndpoint"
      defaults="Authority endpoint"
    />
  ),
  brand: <Trans i18nKey="field.brand" defaults="Brand" />,
  category: <Trans i18nKey="filed.category" defaults="Category" />,
  cityParish: <Trans i18nKey="field.cityParish" defaults="City/Parish" />,
  clientId: (
    <Trans i18nKey="field.tokenAuthentication.clientId" defaults="Client ID" />
  ),
  clientSecret: (
    <Trans
      i18nKey="field.tokenAuthentication.clientSecret"
      defaults="Client secret"
    />
  ),
  code: <Trans i18nKey="field.code" defaults="Code" />,
  copyRelatedOrganizations: (
    <Trans
      i18nKey="field.copyRelatedOrganizations"
      defaults="Copy related organizations"
    />
  ),
  copyRoles: <Trans i18nKey="field.copyRoles" defaults="Copy roles" />,
  country: <Trans i18nKey="field.country" defaults="Country" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  currency: <Trans i18nKey="field.currency" defaults="Currency" />,
  dateTimeRange: (
    <Trans i18nKey="field.dateTimeRange" defaults="Date  and time range" />
  ),
  description: <Trans i18nKey="field.description" defaults="Description" />,
  digit: <Trans i18nKey="field.digit" defaults="digit" />,
  email: <Trans i18nKey="field.email" defaults="Email address" />,
  entity: <Trans i18nKey="field.entity" defaults="Entity" />,
  externalReference: (
    <Trans i18nKey="field.externalReference" defaults="External reference" />
  ),
  firstName: <Trans i18nKey="field.firstName" defaults="First name" />,
  id: <Trans i18nKey="field.id" defaults="ID" />,
  ims: <Trans i18nKey="field.ims" defaults="IMS" />,
  isActive: <Trans i18nKey="field.isActive" defaults="Is active" />,
  issuer: (
    <Trans i18nKey="field.tokenAuthentication.issuer" defaults="Issuer" />
  ),
  language: <Trans i18nKey="field.language" defaults="Language" />,
  lastName: <Trans i18nKey="field.lastName" defaults="Last name" />,
  latitude: <Trans i18nKey="field.latitude" defaults="Latitude" />,
  longitude: <Trans i18nKey="field.longitude" defaults="Longitude" />,
  lowercaseLetter: (
    <Trans i18nKey="field.lowercaseLetter" defaults="lowercase letter" />
  ),
  legalAddress: (
    <Trans
      i18nKey="field.legalAddress"
      defaults="Only max one legal address allowed"
    />
  ),
  mandatoryCardNumber: (
    <Trans
      i18nKey="field.mandatoryCardNumber"
      defaults="Mandatory card number"
    />
  ),
  multileg: <Trans i18nKey="field.multileg" defaults="Multileg hub" />,
  name: <Trans i18nKey="field.name" defaults="Name" />,
  no: <Trans i18nKey="field.no" defaults="No" />,
  orderNumber: <Trans i18nKey="field.orderNumber" defaults="Order number" />,
  organization: <Trans i18nKey="field.organization" defaults="Organization" />,
  organizationCodeList: (
    <Trans
      i18nKey="field.organizationCodeList"
      defaults="Organization code list"
    />
  ),
  prefix: <Trans i18nKey="field.prefix" defaults="Prefix" />,
  primaryOrganization: (
    <Trans
      i18nKey="field.primaryOrganization"
      defaults="Primary organization"
    />
  ),
  primaryPointOfSale: (
    <Trans
      i18nKey="field.primaryPointOfSale"
      defaults="Primary point of sales"
    />
  ),
  primaryUnit: <Trans i18nKey="field.primaryUnit" defaults="Primary unit" />,
  priority: <Trans i18nKey="field.priority" defaults="Priority" />,
  regionalRestrictions: (
    <Trans
      i18nKey="field.regionalRestrictions"
      defaults="Regional restrictions"
    />
  ),
  registrationCode: (
    <Trans i18nKey="field.registrationCode" defaults="Registration code" />
  ),
  role: <Trans i18nKey="field.role" defaults="Role" />,
  roles: <Trans i18nKey="field.roles" defaults="Roles" />,
  salesPoint: <Trans i18nKey="field.salesPoint" defaults="Sales point" />,
  scope: <Trans i18nKey="field.scope" defaults="Scope" />,
  searchable: <Trans i18nKey="field.searchable" defaults="Searchable" />,
  specialCharacter: (
    <Trans i18nKey="field.specialCharacter" defaults="special character" />
  ),
  stopCodeList: (
    <Trans i18nKey="field.stopCodeList" defaults="Stop code list" />
  ),
  street: <Trans i18nKey="field.street" defaults="Street, house number" />,
  streetHouseNumber: (
    <Trans i18nKey="field.streetHouseNumber" defaults="Street, house number" />
  ),
  timeZone: <Trans i18nKey="field.timeZone" defaults="Time zone" />,
  typeInNewPassword: (
    <Trans i18nKey="field.typeInNewPassword" defaults="Type in new password" />
  ),
  unit: <Trans i18nKey="field.unit" defaults="Unit" />,
  uppercaseLetter: (
    <Trans i18nKey="field.uppercaseLetter" defaults="uppercase letter" />
  ),
  username: <Trans i18nKey="field.username" defaults="Username" />,
  validFrom: <Trans i18nKey="field.validFrom" defaults="Valid from" />,
  validServiceFee: (
    <Trans
      i18nKey="field.validServiceFee"
      defaults="Show only valid service fees"
    />
  ),
  validTicketText: (
    <Trans
      i18nKey="field.validTicketText"
      defaults="Show only valid ticket text"
    />
  ),
  validTo: <Trans i18nKey="field.validTo" defaults="Valid to" />,
  vatRegistrationCode: (
    <Trans i18nKey="field.vatRegistrationCode" defaults="VAT number" />
  ),
  wheelchair: (
    <Trans i18nKey="field.wheelchair" defaults="Wheelchair accessible" />
  ),
  yes: <Trans i18nKey="field.yes" defaults="Yes" />,
  zipCode: <Trans i18nKey="field.zipCode" defaults="Zip code" />,
  globalTimeouts: (
    <Trans i18nKey="field.global.timeouts" defaults="Global timeout" />
  ),
});
