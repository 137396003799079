import { createAsyncThunk } from 'store/utils';
import {
  Application,
  BankAccountPayload,
  BookingAccessRight,
  BookingAccessRightPayload,
  ContactingOption,
  ContactingOptionPayload,
  Organization,
  OrganizationAddress,
  OrganizationAddressPayload,
  OrganizationBankAccount,
  OrganizationDetails,
  OrganizationFile,
  OrganizationSearchFilter,
  OrganizationTicketText,
  OrganizationUnit,
  PointOfSale,
  PointsOfSalePayload,
  ServiceFee,
  ServiceFeePayload,
  TicketTextPayload,
  UnitPayload,
} from 'dto/organization';
import { Stop, StopCode } from 'dto/stop';
import { selectCurrentOrganizationId } from 'features/organizations/organizationSelectors';
import { api } from 'features/api';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';

export const setOrganizationsFilter = createAction<
  Partial<OrganizationSearchFilter>
>('setOrganizationsFilter');

export const cleanOrganizationsSearch = createAction(
  'cleanOrganizationsSearch'
);

export const getOrganizationsList = createAsyncThunk<
  Pagination<Organization>,
  Partial<OrganizationSearchFilter> | undefined
>(
  'organization/getOrganizationsList',
  async (filter, { dispatch, getState }) => {
    const filterParams = filter || getState().organization.filter;
    filter && dispatch(setOrganizationsFilter(filter));
    const result = (
      await api.get(
        `organizations${qs.stringify(filterParams, {
          addQueryPrefix: true,
          skipNulls: true,
        })}`
      )
    ).data;
    return {
      ...result,
      limit: filterParams?.limit ?? 10,
    };
  }
);

export const getRetailersList = createAsyncThunk<Array<Organization>>(
  'organization/getRetailersList',
  async () =>
    (
      await api.get(
        `organizations?${qs.stringify({
          AssignedRoles: 'ORGANIZATION_ROLE.RETAILER',
          IsActive: true,
          Count: 1000,
        })}`
      )
    ).data.items
);

export const createOrganization = createAsyncThunk<OrganizationDetails, object>(
  'organization/createOrganization',
  async (payload, { rejectWithValue }) => {
    try {
      return (await api.post(`organizations`, payload)).data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setCurrentOrganization = createAction<
  OrganizationDetails | undefined
>('organization/setCurrentOrganization');

export const getOrganization = createAsyncThunk<
  OrganizationDetails,
  string | undefined
>('organization/getOrganization', async (id, { getState, dispatch }) => {
  const organization = (
    await api.get(
      `organizations/${id ?? selectCurrentOrganizationId(getState())}`
    )
  ).data;
  dispatch(setCurrentOrganization(organization));
  return organization;
});

export const activateOrganization = createAsyncThunk<unknown, string>(
  'organization/activateOrganization',
  async (id, { dispatch }) => {
    await api.post<string>(`organizations/${id}/activate`);
    dispatch(getOrganization(id));
  }
);

export const deactivateOrganization = createAsyncThunk<void, string>(
  'organization/deactivateOrganization',
  async (id, { dispatch }) => {
    await api.post<string>(`organizations/${id}/deactivate`);
    dispatch(getOrganization(id));
  }
);

export const deactivateOrganizations = createAsyncThunk<void, Array<string>>(
  'organization/deactivateOrganizations',
  async (organizationsIds) => {
    await Promise.all(
      organizationsIds.map((id) => api.post(`organizations/${id}/deactivate`))
    );
  }
);

export const updateOrganization = createAsyncThunk<
  OrganizationDetails,
  { id: string }
>(
  'organization/updateOrganization',
  async ({ id, ...payload }) =>
    (await api.put(`organizations/${id}`, payload)).data
);

export const deleteOrganization = createAsyncThunk<void, string>(
  'organization/deleteOrganization',
  async (id) => {
    (await api.delete(`organizations/${id}`)).data;
  }
);

export const deleteAddress = createAsyncThunk<void, string>(
  'organization/deleteAddress',
  async (id, { getState }) => {
    const state = getState();
    (
      await api.delete(
        `/organizations/${selectCurrentOrganizationId(state)}/addresses/${id}`
      )
    ).data;
  }
);

export const getBookingAccessRights = createAsyncThunk<
  Array<BookingAccessRight>,
  string
>('organizations/getBookingAccessRights', async (id) => {
  return (
    await api.get(`/organizations/${id}/retailer-interoperability-permissions`)
  ).data;
});

interface OrganizationActionParams {
  organizationId: string;
  id: string;
}

export const getAvailableUnitsAndPointOfSales = createAsyncThunk<
  { units: Array<OrganizationUnit>; pointsOfSale: Array<PointOfSale> },
  string
>('organization/getAvailablePointOfSales', async (organizationId) => {
  const { units, pointsOfSale } = (
    await api.get(`/organizations/${organizationId}`)
  ).data;

  return { units, pointsOfSale };
});

export const getOrganizationPointOfSale = createAsyncThunk<
  void,
  OrganizationActionParams
>('organization/getPointOfSale', async (id, { getState }) => {
  const state = getState();
  return (
    await api.get(
      `/organizations/${selectCurrentOrganizationId(
        state
      )}/points-of-sale/${id}`
    )
  ).data;
});
//GET /oauth-applications/retailer?pointOfSaleId=<pos_id>
export const getSalesPointClient = createAsyncThunk<Array<Application>, string>(
  'organization/getPointOfSaleClientId',
  async (id) => {
    return (await api.get(`/oauth-applications/retailer?pointOfSaleId=${id}`))
      .data.items;
  }
);

interface App {
  name: string;
  clientId: string;
  pointOfSaleId: string;
}

export const createSalesPointClient = createAsyncThunk<App, object>(
  'organization/createSaleClientId',
  async (payload, { rejectWithValue }) => {
    try {
      return (await api.post(`oauth-applications/retailer`, payload)).data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateOrCreateUnit = createAsyncThunk<void, Partial<UnitPayload>>(
  'organization/updateOrCreateUnit',
  async ({ id, ...payload }, { getState }) => {
    return await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(getState())}/units${
        id ? `/${id}` : ''
      }`,
      { isActive: false, ...payload }
    );
  }
);

export const removeUnits = createAsyncThunk<void, Array<OrganizationUnit>>(
  'organization/removeUnits',
  async (units, { getState }) => {
    await Promise.all(
      units.map(({ id }) =>
        api.delete(
          `/organizations/${selectCurrentOrganizationId(
            getState()
          )}/units/${id}`
        )
      )
    );
  }
);

export const updateOrCreatePointOfSale = createAsyncThunk<
  void,
  Partial<PointsOfSalePayload>
>(
  'organization/updateOrCreatePointOfSale',
  async ({ id, ...payload }, { getState }) => {
    return await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/points-of-sale${id ? `/${id}` : ''}`,
      { isActive: false, ...payload }
    );
  }
);

export const removePointOfSales = createAsyncThunk<void, Array<PointOfSale>>(
  'organization/removePointOfSales',
  async (pointsOfSales, { getState }) => {
    await Promise.all(
      pointsOfSales.map(({ id }) =>
        api.delete(
          `/organizations/${selectCurrentOrganizationId(
            getState()
          )}/points-of-sale/${id}`
        )
      )
    );
  }
);

export const updateOrCreateAddress = createAsyncThunk<
  void,
  Partial<OrganizationAddressPayload>
>(
  'organization/updateOrCreateAddress',
  async ({ id, ...payload }, { getState }) => {
    return await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(getState())}/addresses${
        id ? `/${id}` : ''
      }`,
      { isActive: false, ...payload }
    );
  }
);

export const removeAddresses = createAsyncThunk<
  void,
  Array<OrganizationAddress>
>('organization/removeAddresses', async (addresses, { getState }) => {
  await Promise.all(
    addresses.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/addresses/${id}`
      )
    )
  );
});

export const updateOrCreateContactOption = createAsyncThunk<
  void,
  Partial<ContactingOptionPayload>
>(
  'organization/updateOrCreateAddress',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/contacting-options${id ? `/${id}` : ''}`,
      payload
    )
);

export const removeContactOptions = createAsyncThunk<
  void,
  Array<ContactingOption>
>('organization/removeContactOption', async (contacts, { getState }) => {
  await Promise.all(
    contacts.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/contacting-options/${id}`
      )
    )
  );
});

export const updateOrCreateServiceFee = createAsyncThunk<
  void,
  Partial<ServiceFeePayload>
>(
  'organization/updateOrCreateServiceFee',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/retailer-service-fees${id ? `/${id}` : ''}`,
      payload
    )
);

export const removeServiceFees = createAsyncThunk<void, Array<ServiceFee>>(
  'organization/removeServiceFees',
  async (fees, { getState }) => {
    await Promise.all(
      fees.map(({ id }) =>
        api.delete(
          `/organizations/${selectCurrentOrganizationId(
            getState()
          )}/retailer-service-fees/${id}`
        )
      )
    );
  }
);

export const updateOrCreateBookingAccessRight = createAsyncThunk<
  void,
  Partial<BookingAccessRightPayload>
>(
  'organization/updateOrCreateBookingAccessRight',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/retailer-interoperability-permissions${id ? `/${id}` : ''}`,
      payload
    )
);

export const removeBookingAccessRights = createAsyncThunk<
  void,
  Array<BookingAccessRight>
>('organization/removeBookingAccessRights', async (rights, { getState }) => {
  await Promise.all(
    rights.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/retailer-interoperability-permissions/${id}`
      )
    )
  );
});

///organizations/{organizationId}/codes
export const getStopOrganization = createAsyncThunk<Array<StopCode>, string>(
  'getOrgStopCode',
  async (id) => {
    return (await api.get(`/organizations/${id}/codes`)).data.items;
  }
);

///organizations/{organizationId}/codes/{codeListId}
export const createOrganizationStopCode = createAsyncThunk<
  Stop,
  { orgId: string; codeListId: string; code: string }
>('createOrgStopCode', async ({ orgId, codeListId, code }) => {
  return (
    await api.put(`/organizations/${orgId}/codes/${codeListId}`, { code })
  ).data;
});

///organizations/{organizationId}/codes/{codeListId}

export const removeOrganizationStopCodes = createAsyncThunk<
  void,
  { ls: Array<StopCode>; organizationId: string }
>('organization/deleteStopCodes', async ({ ls, organizationId }) => {
  await Promise.all(
    ls.map(({ codeListId }) =>
      api.delete(`organizations/${organizationId}/codes/${codeListId}`)
    )
  );
});

export const updateOrCreateBankAccount = createAsyncThunk<
  void,
  Partial<BankAccountPayload>
>(
  'organization/updateOrCreateBankAccount',
  async ({ id, ...payload }, { getState }) => {
    return await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(getState())}/bank-account${
        id ? `/${id}` : ''
      }`,
      payload
    );
  }
);

export const removeBankAccounts = createAsyncThunk<
  void,
  Array<OrganizationBankAccount>
>('organization/removeBankAccounts', async (bankAccounts, { getState }) => {
  await Promise.all(
    bankAccounts.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/bank-account/${id}`
      )
    )
  );
});

export const updateOrCreateTicketText = createAsyncThunk<
  void,
  Partial<TicketTextPayload>
>(
  'organization/updateOrCreateTicketText',
  async ({ id, ...payload }, { getState }) => {
    return await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(getState())}/ticket-text${
        id ? `/${id}` : ''
      }`,
      payload
    );
  }
);

export const removeTicketTexts = createAsyncThunk<
  void,
  Array<OrganizationTicketText>
>('organization/removeTicketTexts', async (ticketTexts, { getState }) => {
  await Promise.all(
    ticketTexts.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/ticket-text/${id}`
      )
    )
  );
});

export const getOrganizationFiles = createAsyncThunk<
  Array<OrganizationFile>,
  {
    organizationId: string;
  }
>('organization/getFiles', async ({ organizationId }) => {
  return (await api.get(`organizations/${organizationId}/files`)).data.items;
});

export const deleteOrganizationFiles = createAsyncThunk<
  void,
  { fileEntityIds: Array<string>; organizationId: string }
>('organization/deleteFiles', async ({ organizationId, fileEntityIds }) => {
  return await api.post(
    `/organizations/${organizationId}/files/bulk-delete`,
    fileEntityIds
  );
});

export const updateOrCreateOrganizationFile = createAsyncThunk<
  OrganizationFile,
  { payload: FormData } & {
    organizationId: string;
  } & { fileEntityId?: string }
>(
  'organization/updateOrCreateOrganizationFile',
  async ({ organizationId, fileEntityId, payload }, { rejectWithValue }) => {
    return await (fileEntityId ? api.put : api.post)(
      `/organizations${
        fileEntityId
          ? `/${organizationId}/files/${fileEntityId}`
          : `/${organizationId}/files`
      }`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
      .then((data) => data.data)
      .catch((e) => {
        return rejectWithValue(e);
      });
  }
);
