import { FC, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { FormProvider, useForm, TextField, SearchForm } from '@fleet/shared';
import { Button, FormControl } from '@fleet/shared/mui';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { UsersSearchFormValues } from 'dto/user';
import { useDispatch, useSelector } from 'store/utils';
import { getUsers } from 'features/users/userActions';
import { formSubmit } from 'helpers/formSubmit';
import { usersFilterSelector } from 'features/users/userSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'UsersSearchForm',
  }
);

interface UsersSearchFormProps {}

export const UsersSearchForm: FC<UsersSearchFormProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(usersFilterSelector);
  const onSubmit = useCallback(
    (values: UsersSearchFormValues) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(
          getUsers({
            ...values,
            limit: filter.limit,
            offset: 0,
          })
        );
      }),
    [dispatch, filter]
  );
  const { form, handleSubmit, dirty } = useForm<UsersSearchFormValues>({
    onSubmit,
    subscription: { dirty: true },
  });
  const handleReset = useCallback(() => {
    form.reset({});
    dirty && form.submit();
  }, [form, dirty]);

  return (
    <SearchForm
      title={<TransSubtitle i18nKey="search" />}
      className={classes.root}
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2}>
            <Grid item xs={1}>
              <TextField
                name="userName"
                label={<TransField i18nKey="username" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField name="name" label={<TransField i18nKey="name" />} />
            </Grid>

            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
