import { Button } from '@mui/material';
import { Icon, CardHeader } from '@fleet/shared/mui';
import { OrganizationsTypesProps } from 'routes/Organizations';
import { Link } from 'react-router-dom';
import { Layout } from '@fleet/shared/components/Layout';
import { TransButton } from 'i18n/trans/button';
import { OrganizationsTable } from 'routes/organizations/OrganizationsTable';
import { TransNav } from 'i18n/trans/header';

export const OrganizationsList = ({
  match: { path },
}: OrganizationsTypesProps) => {
  return (
    <Layout
      header={
        <CardHeader title={<TransNav i18nKey="organizations" />}>
          <Button
            startIcon={<Icon name="add" />}
            component={Link}
            to={`${path}/create`}
          >
            <TransButton i18nKey="add" />
          </Button>
        </CardHeader>
      }
    >
      <OrganizationsTable />
    </Layout>
  );
};
