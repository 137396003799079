import type { Pagination } from '@fleet/shared/dto/pagination';
import type { AuditSearchFilter, AuditTrailState } from 'dto/audit';
import { createReducer } from '@reduxjs/toolkit';
import {
  getAuditTrail,
  setAuditTrailFilter,
  setAuditTrailState,
} from 'features/audit/auditActions';
import { AuditTrail } from 'dto/audit';

interface AuditReducer {
  filter?: Partial<AuditSearchFilter>;
  search?: Pagination<AuditTrail>;
  current?: AuditTrailState;
}

const initialState: AuditReducer = {
  filter: {},
};

export const auditReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAuditTrailFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(getAuditTrail.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(setAuditTrailState, (state, action) => {
      state.current = action.payload;
    });
});
