import { TextField, SelectField, useFormContext } from '@fleet/shared/form';
import { Icon, Input, Modal } from '@fleet/shared/mui';
import { FieldArray } from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { Button, Stack, Typography, IconButton } from '@mui/material';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo } from 'react';
import { CellProps } from 'react-table';
import { Price, ServiceFee, ServiceFeePayload } from 'dto/organization';
import { useSelector } from 'react-redux';
import { currentOrganizationSelector } from 'features/organizations/organizationSelectors';
import { useDispatch } from 'store/utils';
import {
  getOrganization,
  updateOrCreateServiceFee,
} from 'features/organizations/organizationActions';

interface FeePricesCellProps {
  cell: CellProps<ServiceFee>;
}

export const FeePricesCell: FC<FeePricesCellProps> = ({ cell }) => {
  const dispatch = useDispatch();
  const currecntOrg = useSelector(currentOrganizationSelector);
  const currencies = useClassificationOptions(ClassificationGroup.CURRENCY);

  const { open, onOpen, onClose } = useModal();
  const form = useFormContext();
  const { getRegisteredFields, focus, blur, getFieldState, change } = form;

  const {
    row: {
      index: rowIndex,
      state: { editable },
    },
    value,
  } = cell;
  const fieldName = useMemo(
    () => `${`rows[${rowIndex}]`}.${cell.column.id}`,
    [rowIndex, cell]
  );
  const getValueStr = useCallback(
    (v: ServiceFee['prices'][number]) =>
      v
        ? `${v.minAmount} — ${v.maxAmount} ${v.currency?.id.split('.')[1]}`
        : '',
    []
  );

  const handleClose = useCallback(
    async (e, reason?: string) => {
      const invalidFields = getFieldState(fieldName)?.invalid;
      const currentServiceFees = currecntOrg?.serviceFees[rowIndex];

      if (reason === 'escapeKeyDown') {
        change(fieldName, getFieldState(fieldName)?.initial);
        onClose();
      }

      if (invalidFields) {
        getRegisteredFields().forEach((field) => {
          focus(field);
          blur(field);
        });
      } else if (reason === 'action') {
        if (currentServiceFees) {
          const prices = getFieldState(fieldName)?.value;
          const payload: ServiceFeePayload = {
            ...currentServiceFees,
            pointOfSaleTypeId: currentServiceFees.pointOfSaleType.id,
            prices: prices.map(({ currency, ...price }: Price) => ({
              minAmount: price.minAmount,
              maxAmount: price.maxAmount,
              currencyId: currency.id,
            })),
            typeId: currentServiceFees.type.id,
          };
          await dispatch(updateOrCreateServiceFee(payload));
          await dispatch(getOrganization());
        }
        onClose();
      }
    },
    [
      blur,
      change,
      currecntOrg,
      dispatch,
      fieldName,
      focus,
      getFieldState,
      getRegisteredFields,
      onClose,
      rowIndex,
    ]
  );
  if (!editable)
    return (
      <div>
        {(value as ServiceFee['prices'])?.map((v, idx) => (
          <Typography key={idx} component="div" variant="body2">
            {getValueStr(v)}
          </Typography>
        ))}
      </div>
    );
  return (
    <div>
      <FieldArray name={fieldName}>
        {({ fields, meta }) => {
          const valueLength = fields.value?.length ?? 0;
          return (
            <>
              <Input
                value={getValueStr(fields.value?.[0])}
                readOnly
                helper={
                  <Stack direction="row" component="span">
                    {valueLength > 1 && (
                      <Typography component="span" variant="body2">
                        {`+${valueLength - 1} more`}
                      </Typography>
                    )}
                    <Typography
                      component="span"
                      onClick={onOpen}
                      variant="body2"
                      color="primary"
                      sx={{ ml: 'auto', cursor: 'pointer' }}
                    >
                      <TransButton i18nKey="addEdit" />
                    </Typography>
                  </Stack>
                }
              />
              <Modal
                title={<TransSubtitle i18nKey="thresholds" />}
                actionButton={{
                  label: <TransButton i18nKey="save" />,
                  onClick: handleClose,
                }}
                open={open}
                onClose={handleClose}
                maxWidth="xs"
              >
                {fields.map((name, index) => (
                  <Stack key={name} direction="row" mb={2}>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      spacing={1}
                      mr={1}
                    >
                      <TextField name={`${name}.minAmount`} positive />
                      <div> - </div>
                      <TextField name={`${name}.maxAmount`} positive />
                    </Stack>
                    <SelectField
                      name={`${name}.currency.id`}
                      options={currencies}
                      required
                    />
                    <IconButton
                      onClick={() => fields.remove(index)}
                      sx={{ alignSelf: 'flex-start' }}
                    >
                      <Icon name="close" />
                    </IconButton>
                  </Stack>
                ))}
                <Button
                  sx={{ p: 0 }}
                  startIcon={<Icon name="plus" />}
                  disabled={meta.invalid}
                  onClick={() => fields.push({})}
                >
                  <Typography variant="body2">
                    <TransButton i18nKey="addNew" />
                  </Typography>
                </Button>
              </Modal>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};
