import type { FC } from 'react';
import { useCallback } from 'react';
import { Button } from '@mui/material';
import { Icon } from '@fleet/shared';
import { useSelector } from 'store/utils';
import { userSelector } from 'features/users/userSelectors';
import { api } from 'features/api';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { TransAlert } from 'i18n/trans/alert';

interface UserPasswordResetModalProps {}

export const UserPasswordResetModal: FC<UserPasswordResetModalProps> = () => {
  const currentUser = useSelector(userSelector)!;
  const alert = useAlert();
  const handleSubmit = useCallback(async () => {
    await api.post(`/users/${currentUser.id}/reset-password`, {
      organizationId: 'e24d2b1c-ebe2-4ade-8e05-bf1db3811cc1',
    });
    alert.success(<TransAlert i18nKey="passwordResetLinkSent" />);
  }, [alert, currentUser.id]);
  return (
    <>
      <Button
        startIcon={<Icon name="mail" size={16} />}
        size="small"
        onClick={handleSubmit}
        disabled
      >
        <TransButton i18nKey="sendPasswordResetLink" />
      </Button>
    </>
  );
};
