import { FC, useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import { Icon, CardHeader } from '@fleet/shared/mui';
import { DrawerForm, DrawerFormProps, Loadable } from '@fleet/shared';
import { Layout } from '@fleet/shared/components/Layout';
import { useSelector } from 'store/utils';
import { currentStopLoadingSelector } from 'features/stops/stopsSelectors';
import { stopListLoadingSelector } from 'features/loading/loadingSelectors';
import { Link, useHistory, useParams } from 'react-router-dom';
import { StopForm } from 'routes/stops/StopForm';
import { StopsTable } from 'routes/stops/StopsTable';
import { TransNav } from 'i18n/trans/header';
import { TransButton } from 'i18n/trans/button';
import { getStopsList } from 'features/stops/stopsActions';
import { useDispatch } from 'react-redux';

interface StopsProps {}

export const Stops: FC<StopsProps> = () => {
  const dispatch = useDispatch();
  const currentStopLoading = useSelector(currentStopLoadingSelector);
  const stopListLoading = useSelector(stopListLoadingSelector);
  const history = useHistory();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isDrawerOpen = useMemo(
    () => Boolean(action) && (action === 'edit' ? !!id : true),
    [action, id]
  );
  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    async (event, reason) => {
      if (reason === 'close') {
        history.replace('/stops');
        await dispatch(getStopsList());
      }
    },
    [dispatch, history]
  );
  return (
    <Loadable loading={stopListLoading}>
      <Layout
        header={
          <CardHeader
            sx={{ padding: '16px 24px' }}
            title={<TransNav i18nKey="stops" />}
          >
            <Button
              startIcon={<Icon name="add" />}
              component={Link}
              to="/stops/create"
            >
              <TransButton i18nKey="add" />
            </Button>
          </CardHeader>
        }
        drawer={
          <DrawerForm open={isDrawerOpen} onClose={handleCloseEditForm}>
            <Loadable loading={currentStopLoading}>
              <StopForm id={id} />
            </Loadable>
          </DrawerForm>
        }
      >
        <StopsTable />
      </Layout>
    </Loadable>
  );
};
