import { TransKeys, createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  cardType: <Trans i18nKey="title.cardType" defaults="Card type" />,
  cardTypes: <Trans i18nKey="title.cardTypes" defaults="Card types" />,
  category: <Trans i18nKey="title.category" defaults="Category" />,
  clientIdModal: (
    <Trans i18nKey="title.clientIdModal" defaults="Client ID: {{name}} " />
  ),
  codeListPrefix: (
    <Trans i18nKey="title.codeListPrefix" defaults="Code list prefix" />
  ),
  issuer: <Trans i18nKey="title.issuer" defaults="Issuer" />,
  ims: <Trans i18nKey="title.ims" defaults="IMS Register" />,
  imsList: <Trans i18nKey="title.imsList" defaults="IMS Registers" />,
  mandatoryCardNumber: (
    <Trans
      i18nKey="title.mandatoryCardNumber"
      defaults="Mandatory card number"
    />
  ),
  organizationCodeList: (
    <Trans
      i18nKey="title.organizationCodeList"
      defaults="Organization code lists"
    />
  ),
  scopes: <Trans i18nKey="title.scopes" defaults="Scopes" />,
  stopCodeList: (
    <Trans i18nKey="title.stopCodeList" defaults="Stop code lists" />
  ),
  token: <Trans i18nKey="title.token" defaults="Token Authentication" />,
  translations: <Trans i18nKey="title.translations" defaults="Translations" />,
});

export type TransTitleKeys = TransKeys<typeof TransTitle>;
