import { FC, useCallback, useMemo } from 'react';
import {
  Header as FleetHeader,
  HeaderProps as FleetHeaderProps,
  Steps,
} from '@fleet/shared';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { TransNav } from 'i18n/trans/header';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentBusinessEntityIdSelector,
  currentUserSelector,
} from 'features/common/commonSelectors';
import { setCurrentBusinessEntity } from 'features/common/commonActions';

const backLinks: FleetHeaderProps['backLinks'] = [];

interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  const { organizations: businessEntities = [], isPlatform: isPlatformUser } =
    useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  const steps: Steps = useMemo(() => {
    const baseSteps = [
      {
        label: <TransNav i18nKey="audit" />,
        path: '/audit',
        basePath: '/audit',
      },
      {
        label: <TransNav i18nKey="users" />,
        path: '/users',
        basePath: '/users',
      },
      {
        label: <TransNav i18nKey="alliances" />,
        path: '/alliances',
        basePath: '/alliances',
      },
      {
        label: <TransNav i18nKey="organizations" />,
        path: '/organizations',
        basePath: '/organizations',
      },
      {
        label: <TransNav i18nKey="stops" />,
        path: '/stops',
        basePath: '/stops',
      },
    ];

    const configurationStep = {
      label: <TransNav i18nKey="configuration" />,
      basePath: '/',
      children: [
        {
          label: <TransNav i18nKey="configuration.organizationCodeLists" />,
          path: '/organization-code-lists',
        },
        {
          label: <TransNav i18nKey="configuration.ims" />,
          path: '/ims',
        },
        {
          label: <TransNav i18nKey="configuration.stopCodeLists" />,
          path: '/stop-code-lists',
        },
        {
          label: <TransNav i18nKey="configuration.cardTypes" />,
          path: '/card-types',
        },
      ],
    };

    return isPlatformUser ? [...baseSteps, configurationStep] : baseSteps;
  }, [isPlatformUser]);

  const onBusinessEntitySelect = useCallback(
    (value) => {
      dispatch(setCurrentBusinessEntity(value));
    },
    [dispatch]
  );

  const { clearStaleState, signoutRedirect } = useAuth();
  const onLogout = useCallback(() => {
    signoutRedirect();
    clearStaleState();
  }, [clearStaleState, signoutRedirect]);

  return (
    <FleetHeader
      steps={steps}
      backLinks={backLinks}
      pathname={pathname}
      businessEntities={businessEntities.map((entity) => ({
        contactType: 'ORGANIZATION',
        ...entity,
      }))}
      currentBusinessEntityId={currentBusinessEntityId}
      onBusinessEntityChange={onBusinessEntitySelect}
      onLogout={onLogout}
      helpPath="https://help.turnit.com/th/latest"
      onMenuClick={history.push}
    />
  );
};
