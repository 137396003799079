import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { classificationReducer } from 'features/classification/classificationSlice';
import { auditReducer } from 'features/audit/auditReducer';
import { userReducer } from 'features/users/userReducer';
import { organizationReducer } from 'features/organizations/organizationReducer';
import { alliancesReducer } from 'features/alliances/alliancesReducer';
import { stopsReducer } from 'features/stops/stopsReducer';
import { imsReducer } from 'features/ims/imsReducer';
import { codeListsReducer } from 'features/codelists/codeListsReducer';
import { commonReducer } from 'features/common/commonReducer';
import { cardTypesReducer } from 'features/cardtypes/cardTypesReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  audit: auditReducer,
  user: userReducer,
  organization: organizationReducer,
  alliances: alliancesReducer,
  stops: stopsReducer,
  ims: imsReducer,
  codeLists: codeListsReducer,
  common: commonReducer,
  cardTypes: cardTypesReducer,
});
