import type { FC } from 'react';
import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Modal, ModalProps } from '@fleet/shared';
import classNames from 'classnames';
import { Diff, DiffProps } from 'components/Diff';

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiPaper-root': {
        overflow: 'unset',
      },
      '& .MuiDialogContent-root': {
        overflow: 'unset',
      },
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
  }),
  {
    name: 'AuditStateModal',
  }
);

interface AuditStateModalProps extends ModalProps, DiffProps {}

export const AuditStateModal: FC<AuditStateModalProps> = ({
  className,
  orig,
  value,
  collapse,
  ...props
}) => {
  const classes = useStyles();
  const hideOrig = useMemo(() => !Boolean(orig), [orig]);

  return (
    <Modal
      className={classNames(classes.root, className)}
      maxWidth={hideOrig ? 'sm' : 'md'}
      fullWidth
      scroll="body"
      {...props}
    >
      <Diff orig={orig} hideOrig={hideOrig} value={value} collapse={collapse} />
    </Modal>
  );
};
