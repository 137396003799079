import { FC, Fragment, useCallback } from 'react';
import { TextField } from '@fleet/shared/form';
import Typography from '@mui/material/Typography';
import { Icon } from '@fleet/shared/mui';
import { Scope } from 'dto/ims';
import { Button } from '@fleet/shared/mui';
import { useFieldArray } from '@fleet/shared/form/hooks/useFieldArray';
import { useForm } from 'react-final-form';
import { TransTitle } from 'i18n/trans/title';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { Stack } from '@mui/material';
import { IconButton } from '@mui/material';

const defaultScope: Scope = '';

export const Scopes: FC<{}> = () => {
  const form = useForm();
  const { fields } = useFieldArray<'tokenAuthentication.scopes', Scope>(
    'tokenAuthentication.scopes',
    {
      form,
    }
  );
  const { push, remove, insert } = fields;
  const handleAddScope = useCallback(async () => {
    push(defaultScope);
  }, [push]);
  const handleTopAddScope = useCallback(async () => {
    insert(0, defaultScope);
  }, [insert]);
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography
            variant="subtitle"
            component="h3"
            sx={{ padding: '16px 0 8px' }}
          >
            <TransTitle i18nKey="scopes" />
          </Typography>
          <Typography
            variant="body2"
            component="h6"
            sx={{ color: '#757B86', marginTop: '10px', marginLeft: '8px' }}
          >
            {`(${fields.value.length})`}
          </Typography>
        </Stack>
        <Button
          sx={{ marginTop: '10px' }}
          variant="text"
          startIcon={<Icon name="add" />}
          onClick={handleTopAddScope}
        >
          <TransButton i18nKey="add" />
        </Button>
      </Stack>
      {Boolean(fields.value.length) ? (
        fields.value.map((field, index) => {
          return (
            <Fragment key={index}>
              <Stack direction="row" alignItems="flex-end">
                <TextField
                  label={<TransField i18nKey="scope" />}
                  name={`tokenAuthentication.scopes.${index}`}
                  required
                />
                <IconButton aria-label="close" onClick={() => remove(index)}>
                  <Icon name="close" size={24} />
                </IconButton>
              </Stack>
              {index === fields.value.length - 1 && (
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<Icon name="plus" />}
                  onClick={handleAddScope}
                  label={<TransButton i18nKey="add" />}
                />
              )}
            </Fragment>
          );
        })
      ) : (
        <Button
          variant="text"
          color="primary"
          size="large"
          startIcon={<Icon name="plus" />}
          onClick={handleAddScope}
          label={<TransButton i18nKey="addNew" />}
        />
      )}
    </>
  );
};
