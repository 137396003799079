import { PASSWORD_MINIMAL_LENGTH } from 'hooks/useChangePassword';
import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransValidate = createTrans({
  lastName: (
    <Trans
      i18nKey="validate.lastName"
      defaults="Must not contain the last name of the user"
    />
  ),
  firstName: (
    <Trans
      i18nKey="validate.firstName"
      defaults="Must not contain the first name of the user"
    />
  ),
  passwordComplexity: (
    <Trans
      i18nKey="validate.passwordComplexity"
      defaults="Password must contain at least three of these - digit, lowercase letter, uppercase letter, special character"
    />
  ),
  passwordMinimalLength: (
    <Trans
      i18nKey="validate.passwordMinimalLength"
      defaults={`Must be at least ${PASSWORD_MINIMAL_LENGTH} characters`}
    />
  ),
  required: <Trans i18nKey="validate.required" defaults="Field is required" />,
  uniq: <Trans i18nKey="validate.uniq" defaults="{{field}} must be unique" />,
  username: (
    <Trans
      i18nKey="validate.username"
      defaults="Must not contain the username of the user"
    />
  ),
});
