import { SelectField } from '@fleet/shared';
import { Grid } from '@mui/material';
import { User } from 'dto/user';
import { makeClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { FC, useMemo } from 'react';

interface UserFormPrimaryFieldsProps {
  currentUser: User;
  primaryOrganizationId: string | undefined;
  onResetPrimaryFields: () => void;
}

export const UserFormPrimaryFields: FC<UserFormPrimaryFieldsProps> = ({
  currentUser,
  primaryOrganizationId,
  onResetPrimaryFields,
}) => {
  const selectedPrimaryOrganization = useMemo(
    () =>
      currentUser.organizations.find(({ id }) => id === primaryOrganizationId),
    [currentUser.organizations, primaryOrganizationId]
  );

  const primaryOrganizationOptions = useMemo(
    () => makeClassificationOptions(currentUser.organizations),
    [currentUser.organizations]
  );
  const primaryUnitOptions = useMemo(
    () => makeClassificationOptions(selectedPrimaryOrganization?.units ?? []),
    [selectedPrimaryOrganization?.units]
  );
  const primaryPointOfSaleOptions = useMemo(
    () =>
      makeClassificationOptions(
        selectedPrimaryOrganization?.pointsOfSale ?? []
      ),
    [selectedPrimaryOrganization?.pointsOfSale]
  );

  return (
    <Grid item xs={3}>
      <Grid container columns={3} spacing={2}>
        <Grid item xs={1}>
          <SelectField
            name="primaryOrganization.id"
            label={<TransField i18nKey="primaryOrganization" />}
            onChange={onResetPrimaryFields}
            options={primaryOrganizationOptions}
            showEmptyOption
          />
        </Grid>
        <Grid item xs={1}>
          <SelectField
            name="primaryUnit.id"
            label={<TransField i18nKey="primaryUnit" />}
            disabled={!selectedPrimaryOrganization}
            options={primaryUnitOptions}
            showEmptyOption
          />
        </Grid>
        <Grid item xs={1}>
          <SelectField
            name="primaryPointOfSale.id"
            disabled={!selectedPrimaryOrganization}
            label={<TransField i18nKey="primaryPointOfSale" />}
            options={primaryPointOfSaleOptions}
            showEmptyOption
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
