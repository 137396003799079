import { createSelector, selector } from 'store/utils';

export const selectUsers = selector((state) => state.user.search);
export const usersSelector = createSelector(selectUsers);

export const selectUsersFilter = selector((state) => state.user.filter);
export const usersFilterSelector = createSelector(selectUsersFilter);

export const selectUsersRoles = selector((state) => state.user.roles);
export const usersRolesSelector = createSelector(selectUsersRoles);

export const selectUser = selector((state) => state.user.current);
export const userSelector = createSelector(selectUser);
