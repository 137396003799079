import { createAsyncThunk } from 'store/utils';
import { api } from 'features/api';
import { Pagination } from '@fleet/shared/dto/pagination';
import { AuditSearchFilter, AuditTrail, AuditTrailState } from 'dto/audit';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';

export const setAuditTrailFilter = createAction<
  Partial<AuditSearchFilter> | undefined
>('setAuditTrailFilter');

export const getAuditTrail = createAsyncThunk<
  Pagination<AuditTrail>,
  Partial<AuditSearchFilter>
>('audit/getAuditTrail', async (values, { dispatch, getState }) => {
  values && dispatch(setAuditTrailFilter(values));
  const { filter } = getState().audit;
  const res = (
    await api.get(
      `/audit-trail${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
        allowDots: true,
      })}`
    )
  ).data;
  return { ...res, limit: filter?.limit ?? 10 };
});

export const setAuditTrailState = createAction<AuditTrailState | undefined>(
  'audit/setAuditTrailState'
);

export const getAuditTrailState = createAsyncThunk<void, string>(
  'audit/getAuditTrailState',
  async (id, { dispatch }) => {
    const state = (await api.get(`/audit-trail/${id}`)).data;
    const { previousStateId } = state;
    dispatch(
      setAuditTrailState({
        id,
        ...state,
        previousState:
          previousStateId &&
          (await api.get(`/audit-trail/${previousStateId}`)).data.state,
      })
    );
  }
);
