import { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Loader, theme, ThemeProvider } from '@fleet/shared/mui';
import { Provider as AlertProvider } from 'react-alert';
import { alertProviderProps } from '@fleet/shared/components/Alert';
import { AuthProvider } from 'react-oidc-context';
import authConf from './authConf';
import { Application } from 'App';
import 'i18n';
import 'styles/index.scss';

render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader active size="fullscreen" />}>
        <Provider store={store}>
          <AuthProvider {...authConf}>
            <AlertProvider {...alertProviderProps}>
              <Application />
            </AlertProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
