import { AxiosError } from 'axios';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { nested } from 'nest-deep';

interface ResponseError {
  errors: Array<{ field: string; message: string }>;
}

type FormErrors = {
  [FORM_ERROR]?: string;
} & SubmissionErrors;

export const serializeErrors = (error: unknown) => {
  const { errors } =
    (error as AxiosError<ResponseError & { message: string }>).response?.data ??
    {};
  const result: FormErrors = {};

  if (Array.isArray(errors)) {
    errors.forEach(({ field, message }) => {
      result[field] = message;
    });
  }

  return nested(result);
};
