import axios, { AxiosError } from 'axios';
import { AlertManager } from 'react-alert';
import { AuthContextProps } from 'react-oidc-context';

type Error = {
  code?: string;
  field?: string;
  message: string;
};

interface ErrorResponse
  extends AxiosError<{
    errors?: Array<Error>;
    business_errors: Array<Error>;
  }> {}

export const getAuthStorage = () => {
  const storageKey = [
    'oidc.user',
    process.env.REACT_APP_AUTH_URL,
    process.env.REACT_APP_IDENTITY_CLIENT_ID,
  ].join(':');

  return JSON.parse(sessionStorage.getItem(storageKey) ?? '{}');
};

export const getToken = () => {
  return getAuthStorage()?.access_token;
};

export const beforeSigninRedirect = () => {
  const { pathname, search } = window.location;
  pathname !== '/signout-oidc' &&
    localStorage.setItem('redirectUri', pathname + search);
};

export const getErrHandler =
  (auth: AuthContextProps, alert: AlertManager) => (error: ErrorResponse) => {
    if (!error.response) return;
    const { data, status, statusText } = error.response;
    if (status === 401 && getAuthStorage()) {
      sessionStorage.clear();
      return auth.signinRedirect();
    }
    if (data) {
      const { errors = [], business_errors = [] } = data;
      const errorList = [...errors, ...business_errors];

      errorList.forEach(({ message }: { message: string }) =>
        alert.show(message, { type: 'error', timeout: 5000 })
      );
    } else {
      status &&
        statusText &&
        alert.show(`${status} ${statusText}`, {
          type: 'error',
          timeout: 5000,
        });
    }
    return Promise.reject(error);
  };

const api = axios.create({
  baseURL: process.env.REACT_APP_API_TARGET,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(function (config) {
  const accessToken = getToken();

  return {
    ...config,
    ...(accessToken && {
      headers: {
        ...(config.headers ?? {}),
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  };
});

export { api };
