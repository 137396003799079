import { FC, useCallback, useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { Icon } from '@fleet/shared/mui';

import { TransTableHead } from 'i18n/trans/table';

import { StopCode } from 'dto/stop';
import { useRowSelect, useFilters } from 'react-table';
import { useDispatch } from 'store/utils';
import { useFormTableControls } from '@fleet/shared/hooks';
import {
  FormProvider,
  useForm,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getStopOrganization,
  createOrganizationStopCode,
  removeOrganizationStopCodes,
} from 'features/organizations/organizationActions';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface StopFCodeProps {
  orgId: string;
  data: Array<StopCode>;
}

export const StopFCode: FC<StopFCodeProps> = ({ data, orgId }) => {
  const dispatch = useDispatch();
  const stops = useClassificationMap(
    ClassificationGroup.ORGANIZATION_CODE_LIST
  );
  const stopCodeList = useClassificationOptions(
    ClassificationGroup.ORGANIZATION_CODE_LIST
  );

  const usedStops = useMemo(
    () => data.map(({ codeListId }) => codeListId),
    [data]
  );
  const availableStopOptions = useMemo(
    () => stopCodeList.filter(({ value }) => !usedStops.includes(value)),
    [stopCodeList, usedStops]
  );

  const columns: TableColumns<StopCode> = useMemo(
    () => [
      {
        accessor: 'codeListId',
        Header: <TransTableHead i18nKey="stopCodeListId" />,
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: stops.get(value) },
            ...availableStopOptions,
          ].filter(Boolean),
        }),
        width: '25%',
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
        width: '75%',
      },
    ],
    [availableStopOptions, stops]
  );
  const { form } = useForm<{ rows: Array<StopCode> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      data,
      columns,
      form,
      intitalState: {
        hiddenColumns: ['id'],
      },
      onRowUpdate: async ({ ...payload }) => {
        await dispatch(createOrganizationStopCode({ orgId, ...payload }));
        await dispatch(getStopOrganization(orgId));
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<StopCode>) => {
      await dispatch(
        removeOrganizationStopCodes({ ls: payload, organizationId: orgId })
      );
      await dispatch(getStopOrganization(orgId));
    },
    [dispatch, orgId]
  );
  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          getTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          table={table}
        />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noCodesFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
