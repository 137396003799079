import { createAsyncThunk } from 'store/utils';
import { api } from 'features/api';
import { Ims, ImsConfigurations } from 'dto/ims';
import { createAction } from '@reduxjs/toolkit';




export const getImsConfigurations = createAsyncThunk<ImsConfigurations>(
  'ims/get-configurations',
  async () => (await api.get('/ims-configurations?limit=1000000',)).data.items
);

export const createIms = createAsyncThunk<Ims, object>(
  'ims/create',
  async (payload, { rejectWithValue }) => {
    try {
      return (await api.post(`ims-configurations`, payload)).data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateIms = createAsyncThunk<Ims, {id:string}>(
  'ims/update',
  async ({ id, ...payload },{rejectWithValue}) =>{
    try{
      return (await api.put(`ims-configurations/${id}`, payload)).data
    } catch (e){
      return rejectWithValue(e);
    }
  }
    // (await api.put(`ims-configurations/${id}`, payload)).data
);

export const setIms = createAction<Ims | undefined>('ims/setIms')

export const getImsById = createAsyncThunk<Ims, string>(
  'ims/get',
  async(id,store) =>{
    const ims = (await api.get(`/ims-configurations/${id}`)).data
    store.dispatch(setIms(ims));
    return ims
  }
)