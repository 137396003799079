import type { FC } from 'react';
import type { MergeView } from 'codemirror/addon/merge/merge';
import { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import * as diffLib from 'diff-match-patch';
import CodeMirror from 'codemirror';
import 'codemirror/addon/merge/merge.js';
import 'codemirror/mode/javascript/javascript.js';
import { alpha } from '@mui/material/styles';
import { darken } from '@mui/material';
import { Theme } from '@fleet/shared';

Object.keys(diffLib).forEach((key) => {
  // @ts-ignore
  window[key] = diffLib[key];
});

const useStyles = makeStyles<Theme, DiffProps>(
  (theme) => ({
    root: {
      '& .CodeMirror-scroll': { maxHeight: 400 },
      '& .CodeMirror-merge-left': {
        display: ({ hideOrig }) => (hideOrig && 'none') as string,
      },
      '& .CodeMirror-merge-gap': {
        display: ({ hideOrig }) => (hideOrig && 'none') as string,
      },
      '& .CodeMirror-merge-l-chunk': {
        background: alpha(theme.palette.error.main, 0.1),
        '&.CodeMirror-merge-l-chunk-start, &.CodeMirror-merge-l-chunk-end': {
          borderColor: alpha(theme.palette.error.main, 0.2),
        },
      },
      '& .CodeMirror-merge-pane-rightmost': {
        width: ({ hideOrig }) => (hideOrig && '100%') as string,
        '& .CodeMirror-linebackground': {
          background: alpha(theme.palette.success.main, 0.1),
          '&.CodeMirror-merge-l-chunk-start, &.CodeMirror-merge-l-chunk-end': {
            borderColor: alpha(theme.palette.success.main, 0.2),
          },
        },
      },
      '& .CodeMirror-merge-r-deleted, & .CodeMirror-merge-l-deleted': {
        background: alpha(theme.palette.error.main, 0.2),
        color: theme.palette.error.main,
      },
      '& .CodeMirror-merge-r-inserted, & .CodeMirror-merge-l-inserted': {
        background: alpha(theme.palette.success.main, 0.2),
        color: darken(theme.palette.success.main, 0.5),
      },
    },
  }),
  {
    name: 'Diff',
  }
);

export interface DiffProps {
  orig: string;
  hideOrig?: boolean;
  value: string;
  collapse?: boolean;
}

export const Diff: FC<DiffProps> = (props) => {
  const { orig, value, collapse = true } = props;
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { current: target } = ref;
    if (!value || !target) return;
    target.innerHTML = '';
    const mv = CodeMirror.MergeView(target, {
      origLeft: orig ?? '',
      value,
      allowEditingOriginals: false,
      revertButtons: false,
      showDifferences: true,
      lineNumbers: true,
      mode: 'text/javascript',
      connect: 'align',
      collapseIdentical: collapse,
      viewportMargin: Infinity,
    });
    resize(mv);
  }, [collapse, orig, value]);

  const classes = useStyles(props);
  return <div ref={ref} className={classes.root} />;
};

function mergeViewHeight(view: MergeView) {
  function editorHeight(editor?: CodeMirror.Editor) {
    if (!editor) return 0;
    return editor.getScrollInfo().height;
  }
  return Math.max(
    editorHeight(view.leftOriginal()),
    editorHeight(view.editor()),
    editorHeight(view.rightOriginal())
  );
}

function resize(view: MergeView) {
  let height = mergeViewHeight(view);
  for (;;) {
    // TODO, resize ???
    // view.editor().setSize(null, height);
    // view.leftOriginal()?.setSize(null, height);
    // view.rightOriginal()?.setSize(null, height);

    const newHeight = mergeViewHeight(view);
    if (newHeight >= height) break;
    else height = newHeight;
  }
  // view.wrap.style.height = `${height}px`;
}
