import { createAsyncThunk } from 'store/utils';
import {
  Classification,
  ClassificationGroup,
  CodeList,
} from 'dto/classification';
import { api } from 'features/api';

export const getClassification = createAsyncThunk<
  [ClassificationGroup, Array<Classification>],
  ClassificationGroup
>('getClassification', async (groupName) => [
  groupName,
  (await api.get(`/classifications/groups/${groupName}`)).data.items,
]);

export const getCountries = createAsyncThunk<Array<Classification>>(
  'countries',
  async () => (await api.get('/countries')).data.items
);

export const getStopCodeList = createAsyncThunk<Array<CodeList>>(
  'codeList',
  async () => (await api.get(`/code-lists/stop`)).data.items
);

export const getOrganizationCodeList = createAsyncThunk<Array<CodeList>>(
  'orgList',
  async () => (await api.get(`/code-lists/organization`)).data.items
);

export const getImsList = createAsyncThunk<Array<Classification>>(
  'imsList',
  async () => (await api.get(`/ims-configurations`)).data.items
);

export const getClassifications = createAsyncThunk(
  'getClassifications',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(getCountries()),
      dispatch(getStopCodeList()),
      dispatch(getImsList()),
      dispatch(getOrganizationCodeList()),
      dispatch(getClassification(ClassificationGroup.CULTURE)),
      dispatch(getClassification(ClassificationGroup.ORGANIZATION_FILE)),
      dispatch(getClassification(ClassificationGroup.CURRENCY)),
      dispatch(getClassification(ClassificationGroup.ORGANIZATION_ROLE)),
      dispatch(getClassification(ClassificationGroup.POINT_OF_SALE_TYPE)),
      dispatch(getClassification(ClassificationGroup.ADDRESS_TYPE)),
      dispatch(
        getClassification(ClassificationGroup.RETAILER_SERVICE_FEE_TYPE)
      ),
      dispatch(getClassification(ClassificationGroup.TEMPLATE_TYPE)),
      dispatch(getClassification(ClassificationGroup.CONTACTING_OPTION_TYPE)),
      dispatch(getClassification(ClassificationGroup.CONTACTING_PURPOSE_TYPE)),
      dispatch(getClassification(ClassificationGroup.POS_TYPE)),
      dispatch(getClassification(ClassificationGroup.BANK_ACCOUNT_TYPE)),
      dispatch(getClassification(ClassificationGroup.CARD_TYPE)),
    ]);
  }
);
