import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import {
  FormProvider,
  useForm,
  Checkbox,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
} from '@fleet/shared';
import { useRowSelect, useFilters } from 'react-table';
import { PointOfSale } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import {
  getOrganization,
  removePointOfSales,
  updateOrCreatePointOfSale,
} from 'features/organizations/organizationActions';
import { useDispatch } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { PointOfSaleClientIdModal } from 'routes/organizations/Modal/PointOfSaleClientIdModal';
import { useHighLightAuditRow } from 'routes/organizations/hooks/useHighLightAuditRow';
import { useFormTableControls } from '@fleet/shared/hooks';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface PointsOfSaleProps {
  data: Array<PointOfSale>;
}
export const PointsOfSale: FC<PointsOfSaleProps> = ({ data }) => {
  const dispatch = useDispatch();
  const pointOfSaleTypes = useClassificationOptions(
    ClassificationGroup.POINT_OF_SALE_TYPE
  );

  const toolTypeOptions = useClassificationOptions(
    ClassificationGroup.POS_TYPE
  );

  const columns: TableColumns<PointOfSale> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '17vw',
      },
      {
        id: 'type.id',
        accessor: ({ type }) => type?.id,
        Header: <TransTableHead i18nKey="type" />,
        type: 'select',
        editableProps: { options: pointOfSaleTypes },
        width: '17vw',
      },
      {
        id: 'toolType.id',
        accessor: ({ toolType }) => toolType?.id,
        Header: <TransTableHead i18nKey="typeOfTool" />,
        type: 'select',
        conditions: {
          disabled: {
            when: 'type.id',
            is: (val) => val !== 'POINT_OF_SALE_TYPE.AGENT_TOOL',
          },
        },
        editableProps: { options: toolTypeOptions, required: false },
        width: '17vw',
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        type: 'checkbox',
        editableProps: { required: false },
        width: '17vw',
      },
      {
        id: 'clientId',
        accessor: 'id',
        Cell: ({ value, row }) => {
          if (
            value &&
            row.values['type.id'] === 'POINT_OF_SALE_TYPE.API_CONSUMER'
          )
            return (
              <PointOfSaleClientIdModal id={value} name={row.values.name} />
            );
          return null;
        },
        width: '20vw',
      },
    ],
    [pointOfSaleTypes, toolTypeOptions]
  );

  const { form } = useForm<{ rows: Array<PointOfSale> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      data,
      columns,
      form,
      onRowUpdate: async ({ type, toolType, ...values }) => {
        const payload = {
          typeId: type.id,
          toolType:
            type.id === 'POINT_OF_SALE_TYPE.AGENT_TOOL' ? toolType.id : null,
          ...values,
        };

        await dispatch(updateOrCreatePointOfSale(payload));
        await dispatch(getOrganization());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowActive
  );
  useHighLightAuditRow(table, data);

  const handleActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isActive', e.target.checked),
    [table]
  );

  const onRowsRemove = useCallback(
    async (payload: Array<PointOfSale>) => {
      await dispatch(removePointOfSales(payload));
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Checkbox
            size="small"
            sx={{ flex: 1 }}
            label={<TransField i18nKey="activePointsOfSales" />}
            onChange={handleActiveFilterToggle}
            inline
          />
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          getTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          table={table}
        />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noPointsOfSalesFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
